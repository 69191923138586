import { Image } from 'antd';

import cabinetPiece1Board from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_01_A.png';
import cabinetPiece1Panel from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_01_B.jpg';

import cabinetPiece2Board from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_02_A.png';
import cabinetPiece2Panel from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_02_B.jpg';

import cabinetPiece3Board from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_03_A.png';
import cabinetPiece3Panel from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_03_B.jpg';

import cabinetPiece4Board from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_04_A.png';
import cabinetPiece4Panel from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_04_B.jpg';

import cabinetPiece5Board from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_05_A.png';
import cabinetPiece5Panel from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_05_B.jpg';

import cabinetPiece6Board from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_06_A.jpg';
import cabinetPiece6Panel from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_06_B.jpg';

import cabinetPiece8Board from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_08_A.png';
import cabinetPiece8Panel from '../../../assets/Kitchen/KitchenItems2/Cabinet-piece_08_B.jpg';

import inductionCookerBoard from '../../../assets/Kitchen/KitchenItems2/Induction-cooker_01_A.png';
import inductionCookerPanel from '../../../assets/Kitchen/KitchenItems2/Induction-cooker_01_B.png';

import cookTopOven1Board from '../../../assets/Kitchen/KitchenItems2/Cooktops&Ovens_01_A.png';
import cookTopOven1Panel from '../../../assets/Kitchen/KitchenItems2/Cooktops&Ovens_01_B.png';
import cookTopOven2Board from '../../../assets/Kitchen/KitchenItems2/Cooktops&Ovens_02_A.png';
import cookTopOven2Panel from '../../../assets/Kitchen/KitchenItems2/Cooktops&Ovens_02_B.png';

import counterTop1Board from '../../../assets/Kitchen/KitchenItems2/Countertops_01_A.png';
import counterTop1Panel from '../../../assets/Kitchen/KitchenItems2/Countertops_01_B.jpg';
import counterTop2Board from '../../../assets/Kitchen/KitchenItems2/Countertops_02_A.png';
import counterTop2Panel from '../../../assets/Kitchen/KitchenItems2/Countertops_02_B.png';

import kitchenCabinet1Board from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_01_A.png';
import kitchenCabinet1Panel from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_01_B.jpg';

import kitchenCabinet2Board from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_02_A.png';
import kitchenCabinet2Panel from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_02_B.png';

import kitchenCabinet3Board from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_03_A.png';
import kitchenCabinet3Panel from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_03_B.png';

import kitchenCabinet4Board from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_04_A.png';
import kitchenCabinet4Panel from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_04_B.png';

import kitchenCabinet5Board from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_05_A.png';
import kitchenCabinet5Panel from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_05_B.png';

import kitchenCabinet6Board from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_06_A.png';
import kitchenCabinet6Panel from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_06_B.png';

import kitchenCabinet7Board from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_07_A.png';
import kitchenCabinet7Panel from '../../../assets/Kitchen/KitchenItems2/Kitchen-cabinet_07_B.png';


export const ItemIcon = ({ src }) => <Image src={src} height='100%' />;


export const kitchenItems1 = [
    {
        "key": "cabinetPiece1",
        "title": "Cabinet Piece 1",
        "type": "item",
        "description": "1.11m x 0.70m x 0.89m (DWH)",
        "icon": <ItemIcon src={cabinetPiece1Panel} />,
        "panelIcon": cabinetPiece1Panel,
        "boardIcon": cabinetPiece1Board,
        "height": 25,
        "width": 50,
        "zIndex": 3
    },
    {
        "key": "cabinetPiece2",
        "title": "Cabinet Piece 2",
        "type": "item",
        "description": "0.41m x 0.57m x 0.91m (DWH)",
        "icon": <ItemIcon src={cabinetPiece2Panel} />,
        "panelIcon": cabinetPiece2Panel,
        "boardIcon": cabinetPiece2Board,
        "height": 25,
        "width": 25,
        "zIndex": 3
    },
    {
        "key": "cabinetPiece3",
        "title": "Cabinet Piece 3",
        "type": "item",
        "description": "0.52m x 0.66m x 0.91m (DWH)",
        "icon": <ItemIcon src={cabinetPiece3Panel} />,
        "panelIcon": cabinetPiece3Panel,
        "boardIcon": cabinetPiece3Board,
        "height": 25,
        "width": 25,
        "zIndex": 3
    },
    {
        "key": "cabinetPiece4",
        "title": "Cabinet Piece 4",
        "type": "item",
        "description": "0.78m x 0.60m x 1.27m (DWH)",
        "icon": <ItemIcon src={cabinetPiece4Panel} />,
        "panelIcon": cabinetPiece4Panel,
        "boardIcon": cabinetPiece4Board,
        "height": 25,
        "width": 50,
        "zIndex": 3
    },
    {
        "key": "cabinetPiece5",
        "title": "Cabinet Piece 5",
        "type": "item",
        "description": "0.82m x 0.81m x 0.91m (DWH)",
        "icon": <ItemIcon src={cabinetPiece5Panel} />,
        "panelIcon": cabinetPiece5Panel,
        "boardIcon": cabinetPiece5Board,
        "height": 21,
        "width": 35,
        "zIndex": 3
    },
    {
        "key": "cabinetPiece6",
        "title": "Cabinet Piece 6",
        "type": "item",
        "description": "0.23m x 0.66m x 0.91m (DWH)",
        "icon": <ItemIcon src={cabinetPiece6Panel} />,
        "panelIcon": cabinetPiece6Panel,
        "boardIcon": cabinetPiece6Board,
        "height": 17,
        "width": 22,
        "zIndex": 3
    },
    {
        "key": "cabinetPiece7",
        "title": "Cabinet Piece 7",
        "type": "item",
        "description": "0.77m x 0.55m x 0.63m (DWH)",
        "icon": <ItemIcon src={cabinetPiece8Panel} />,
        "panelIcon": cabinetPiece8Panel,
        "boardIcon": cabinetPiece8Board,
        "height": 15,
        "width": 31,
        "zIndex": 3
    },
    {
        "key": "inductionCooker",
        "title": "Induction Cooker",
        "type": "item",
        "description": "0.60m x 0.51m x 5m (DWH)",
        "icon": <ItemIcon src={inductionCookerPanel} />,
        "panelIcon": inductionCookerPanel,
        "boardIcon": inductionCookerBoard,
        "height": 15,
        "width": 31,
        "zIndex": 3
    },
    {
        "key": "cookTopOven1",
        "title": "Cooktop & Oven 1",
        "type": "item",
        "description": "0.73m x 0.72m x 1.20m (DWH)",
        "icon": <ItemIcon src={cookTopOven1Panel} />,
        "panelIcon": cookTopOven1Panel,
        "boardIcon": cookTopOven1Board,
        "height": 15,
        "width": 24,
        "zIndex": 3
    },
    {
        "key": "cookTopOven2",
        "title": "Cooktop & Oven 2",
        "type": "item",
        "description": "0.60m x 0.65m x 5m (DWH)",
        "icon": <ItemIcon src={cookTopOven2Panel} />,
        "panelIcon": cookTopOven2Panel,
        "boardIcon": cookTopOven2Board,
        "height": 15,
        "width": 31,
        "zIndex": 3
    },
    {
        "key": "counterTop1",
        "title": "Counter Top 1",
        "type": "item",
        "description": "0.6m x 0.65m x 5m (DWH)",
        "icon": <ItemIcon src={counterTop1Panel} />,
        "panelIcon": counterTop1Panel,
        "boardIcon": counterTop1Board,
        "height": 15,
        "width": 31,
        "zIndex": 3
    },
    {
        "key": "counterTop2",
        "title": "Counter Top 2",
        "type": "item",
        "description": "0.6m x 0.65m x 5m (DWH)",
        "icon": <ItemIcon src={counterTop2Panel} />,
        "panelIcon": counterTop2Panel,
        "boardIcon": counterTop2Board,
        "height": 15,
        "width": 31,
        "zIndex": 3
    },
    {
        "key": "kitchenCabinet1",
        "title": "Kitchen Cabinet 1",
        "type": "item",
        "description": "2.02m x 2m x 1.26m (DWH)",
        "icon": <ItemIcon src={kitchenCabinet1Panel} />,
        "panelIcon": kitchenCabinet1Panel,
        "boardIcon": kitchenCabinet1Board,
        "height": 30,
        "width": 60,
        "zIndex": 3
    },
    {
        "key": "kitchenCabinet2",
        "title": "Kitchen Cabinet 2",
        "type": "item",
        "description": "3.73m x 2.84m x 2.48m (DWH)",
        "icon": <ItemIcon src={kitchenCabinet2Panel} />,
        "panelIcon": kitchenCabinet2Panel,
        "boardIcon": kitchenCabinet2Board,
        "height": 30,
        "width": 60,
        "zIndex": 3
    },
    {
        "key": "kitchenCabinet3",
        "title": "Kitchen Cabinet 3",
        "type": "item",
        "description": "3.95m x 2.13m x 1.17m (DWH)",
        "icon": <ItemIcon src={kitchenCabinet3Panel} />,
        "panelIcon": kitchenCabinet3Panel,
        "boardIcon": kitchenCabinet3Board,
        "height": 30,
        "width": 60,
        "zIndex": 3
    },
    {
        "key": "kitchenCabinet4",
        "title": "Kitchen Cabinet 4",
        "type": "item",
        "description": "2.64m x 1.41m x 2.39m (DWH)",
        "icon": <ItemIcon src={kitchenCabinet4Panel} />,
        "panelIcon": kitchenCabinet4Panel,
        "boardIcon": kitchenCabinet4Board,
        "height": 30,
        "width": 60,
        "zIndex": 3
    },
    {
        "key": "kitchenCabinet5",
        "title": "Kitchen Cabinet 5",
        "type": "item",
        "description": "3.36m x 2.23m x 2.29m (DWH)",
        "icon": <ItemIcon src={kitchenCabinet5Panel} />,
        "panelIcon": kitchenCabinet5Panel,
        "boardIcon": kitchenCabinet5Board,
        "height": 30,
        "width": 60,
        "zIndex": 3
    },
    {
        "key": "kitchenCabinet6",
        "title": "Kitchen Cabinet 6",
        "type": "item",
        "description": "3.33m x 0.97m x 2.14m (DWH)",
        "icon": <ItemIcon src={kitchenCabinet6Panel} />,
        "panelIcon": kitchenCabinet6Panel,
        "boardIcon": kitchenCabinet6Board,
        "height": 30,
        "width": 60,
        "zIndex": 3
    },
    {
        "key": "kitchenCabinet7",
        "title": "Kitchen Cabinet 7",
        "type": "item",
        "description": "3.86m x 3.20m x 2.47m (DWH)",
        "icon": <ItemIcon src={kitchenCabinet7Panel} />,
        "panelIcon": kitchenCabinet7Panel,
        "boardIcon": kitchenCabinet7Board,
        "height": 30,
        "width": 60,
        "zIndex": 3
    }    
];