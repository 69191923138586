import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styles from '../../App.module.css';
import { Group, Stage } from 'react-konva';
import PlanLayer from '../../components/plan-layer';
import RoomLayer from '../../components/room-layer/room-layer';
import Topbar from '../../components/topbar/topBar.js';
import RoomPanel from '../../components/panels/roompanel';
import Selectpanel from '../../components/panels/selectpanel';
import FloorPanel from '../../components/panels/floorPanel';
import Drawpanel from '../../components/panels/drawpanel';
import RoomTypeDialog from '../../components/common/dialog/roomtypedialog';
import RoomShapeDialog from '../../components/common/dialog/roomshapedialog';
import { useAuthContext } from '../../hooks/contextHooks/useAuthContext';
import axios from 'axios';
import config from '../../config/config.json';
import Specificpanel from '../../components/panels/specificpanel/specificPanel.js';
import { InitialLShaped, InitialRShaped, InitialTShaped, InitialZShaped } from '../../api/data/wallData.js';
import { Button, Flex, Popover } from 'antd';
import HelperModal from '../../components/modals/helperModal/helperModal.js';
import ItemMenu from '../../components/item-menu/item-menu.js';
import Panel from '../../components/common/panel/panel.js';
import cloneDeep from 'lodash/cloneDeep';
import menuItems from '../../api/data/menu.js';
import { useSnapshotContext } from '../../hooks/contextHooks/useSnapshotContext.js';
import { useDragContext } from '../../contexts/DragContext.js';
import { CollapseIcon, ExpandIcon } from '../../components/common/icons/mainIcons/mainIcons.js';
import { rotateCache } from '../../api/data/menu.js'
import { useScreenshotContext } from '../../hooks/contextHooks/useScreenshotContext.js';
import { UseScreenShotHooks } from '../../hooks/apiHooks/screenshotHooks/useScreenShotHooks.js';
import TopbarFunctions from '../../functions/topbarFunctions.js';
import { generateId, isSelectedItemRotatable, isSelectedItemWindow, isSubGroup } from '../../functions/helper.js';
import RightClickMenu from '../../components/right-click-menu/rightClickMenu.js';
import { detectItemCollision, findNextZ, findPreviousZ } from '../../functions/zoneFinder.js';
import ShortcutPanel from '../../components/panels/shortcutPanel/shortcutPanel.js';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n.js';
import { HFlip } from '../../components/common/icons/shortcutIcons/shortcutIcons.js';



const useTransform = (initialTransform, { minScale = 0.1, maxScale = 5, minDegree = 0, maxDegree = 270 }) => {
    const [transform, setTransform] = useState(initialTransform);

    const setZoom = useCallback((zoomFunc, rotateFunc, centerX, centerY) => {
        setTransform(t => {
            const newScale = Math.min(Math.max(zoomFunc(t.scale), minScale), maxScale);
            const newDegree = Math.min(Math.max(rotateFunc(t.degree), minDegree), maxDegree);
            const mouseXBefore = (centerX - t.dragX + t.offsetX) / t.scale;
            const mouseYBefore = (centerY - t.dragY + t.offsetY) / t.scale;
            const mouseXAfter = (centerX - t.dragX + t.offsetX) / newScale;
            const mouseYAfter = (centerY - t.dragY + t.offsetY) / newScale;
            const dx = mouseXAfter - mouseXBefore;
            const dy = mouseYAfter - mouseYBefore;
            const newOffsetX = t.offsetX - dx * newScale;
            const newOffsetY = t.offsetY - dy * newScale;

            return {
                ...t,
                scale: newScale,
                offsetX: newOffsetX,
                offsetY: newOffsetY,
                degree: newDegree
            };
        });
    }, []);

    return [transform, setTransform, setZoom];
};

export default function Whiteboard() {
    // Hooks
    const { id } = useParams();
    const [topBarMode, setTopBarMode] = React.useState("Hand");
    const [roomType, setRoomType] = React.useState("");
    const [roomShape, setRoomShape] = React.useState("");
    const [showDialog, setShowDialog] = React.useState(false);
    // we use a single state for offset and scale to avoid multiple re-renders
    //Hooks for Draw Modes
    const [lineDrawing, setLineDrawing] = useState(false);
    const [selecting, setSelecting] = useState(false);
    const [cursorType, setCursorType] = React.useState("default");
    const [cursorPosition, setCursorPosition] = useState(null);
    const startPointRef = useRef(null);
    const currPointRef = useRef(null);
    const isDrawingRef = useRef(false);
    const deletedRef = useRef(true);
    const wallsRef = useRef([]);
    const shapeRefs = useRef([]);
    const controlPressedRef = useRef(false);
    const prevTopBarMode = React.useState("Hand");
    const [selectedWall, setSelectedWall] = useState(undefined);
    const [deleteState, setDeleteState] = useState(false);
    const [deleteWallId, setDeleteWallId] = useState(false);


    const [isDrawing, setIsDrawing] = useState(false);
    const [onPanel, setOnPanel] = useState(false);
    const [path, setPath] = useState([]);


    const [walls, setWalls] = useState([])
    const [lineColor, setLineColor] = useState('#9D9D9B');
    const [lineThickness, setLineThickness] = useState(10);
    const [lineLength, setLineLength] = useState(10);
    const [history, setHistory] = useState(-1);
    const [moving, setMoving] = React.useState(false);

    const [items, setItems] = useState([]);
    const points = useRef([]);
    const addingJoint = useRef(false);

    // For Select Index of Objects & Walls
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedFloor, setSelectedFloor] = useState(null);
    const [projectName, setProjectName] = useState("");
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [selectedItemIndexes, setSelectedItemIndexes] = useState(selectedItemIndex !== -1 ? [selectedItemIndex] : []);
    const [deleteIndex, setDeleteIndex] = useState(-1);
    const [showMeasurements, setShowMeasurements] = useState(false);
    const [copyItems, setCopyItems] = useState([]);
    const [hoverItem, setHoverItem] = useState(null);

    const [changeRotation, setChangeRotation] = useState(false);

    const [clickedPos, setClickedPos] = useState({});
    const [rightClicked, setRightClicked] = useState(false);
    const [contextMenuVisible, setContextMenuVisible] = useState(false);

    const [screenshots, setScreenshots] = useState([]);

    const [floors, setFloors] = useState([]);

    const [lockedWallsState, setLockedWallsState] = useState(false);



    const [transform, setTransform, setZoom] = useTransform({ offsetX: -window.innerWidth / 2, offsetY: -window.innerHeight / 2, scale: 1, dragX: 0, dragY: 0, degree: 0 }, { minScale: 0.1, maxScale: 5, minDegree: 0, maxDegree: 270 });

    // Constants
    const width = window.innerWidth;
    const height = window.innerHeight;
    const { offsetX, offsetY, scale, dragX, dragY, degree } = transform;
    const { snapshot, index, snapshotDispatch } = useSnapshotContext();
    const { draggedItem } = useDragContext();
    const { ssTrigger } = useScreenshotContext();
    const [itemRotator, setItemRotator] = useState(0);

    // Use State for Helper Modal opening
    const [helperOpen, setHelperOpen] = useState(false);
    // Use State for Left Panel Collapse
    const [leftPanelOpen, setLeftPanelOpen] = useState(true);
    const [rightPanelOpen, setRightPanelOpen] = useState(true);

    const [mouseOnItemsCheck, setMouseOnItemsCheck] = useState(false);

    const [clickedItem, setClickedItem] = useState(null);

    const [groupedItems, setGroupedItems] = useState([]);
    const [hiddenItems, setHiddenItems] = useState([]);


    const { captureScreenshot, fetchScreenshot } = UseScreenShotHooks(id, screenshots, setScreenshots);

    const [shortCutVisible, setShortcutVisible] = useState(false);


    const handleWheel = useCallback(e => {
        e.evt.preventDefault();

        const pointerX = e.evt.clientX;
        const pointerY = e.evt.clientY;
        const scaleFactor = e.evt.deltaY > 0 ? 1 / 1.05 : 1.05;
        setZoom((scale) => scale * scaleFactor, (degree) => degree * 1, pointerX, pointerY);


    }, [setZoom]);


    const selectedItemRef = useRef(null);
    useEffect(() => {
        selectedItemRef.current = selectedItem;
    }, [selectedItem]);

    const handleDrag = useCallback(e => {
        if (e.target !== e.target.getStage()) {
            return;
        }

        //e.evt.preventDefault();
        if (draggedItem) {
            e.preventDefault();
        }

        const stage = e.target;
        const x = stage.x();
        const y = stage.y();
        setTransform(t => ({
            ...t,
            dragX: x,
            dragY: y
        }));
    }, []);

    const handleRightClick = (e) => {
        e.preventDefault();
        if (e.type === 'contextmenu' && !e.ctrlKey) {
            setClickedPos({ x: e.clientX, y: e.clientY });
            setRightClicked(true);
            setContextMenuVisible(true);
        }
    };


    const handleMultipleObjectDeletion = () => {
        let updatedItems = cloneDeep(items);
        selectedItemIndexes.forEach((id) => {
            let data = updatedItems.splice(id, 1);
            // Remove from groupedItems
            setGroupedItems(prevGroupedItems => {
                return prevGroupedItems
                    .map(group => group.filter(itemId => itemId !== data.id)) // Filter out the selected id from each group
                    .filter(group => group.length > 0); // Remove empty groups
            });
            const selectedShapeRef = shapeRefs.current[id]?.current;
            if (selectedShapeRef) {
                selectedShapeRef.destroy(); // remove the Konva node from the scene
            }
        });

        setItems(updatedItems);


    };

    const handleObjectDeletion = () => {
        if (selectedItemIndexes.length === 1) {
            handleSingleItemDelete(selectedItemIndex);
        }
        if (selectedItemIndexes.length > 1) {
            handleMultipleObjectDeletion();
        }
        setSelectedItem(null);
        setSelectedItemIndex(-1);
        setSelectedItemIndexes([]);
        setSelectedWall(undefined);

        const copiedPoints = cloneDeep(points.current);
        const copiedWalls = cloneDeep(wallsRef.current)

        snapshotDispatch({
            type: 'SNAPSHOT', payload: {
                items: items.filter((item, index) => index !== selectedItemIndex),
                walls: copiedWalls,
                points: copiedPoints
            }
        })
    }

    const { cut, copy, paste } = TopbarFunctions({
        copyItems,
        setCopyItems,
        selectedItem,
        handleObjectDeletion,
        transform,
        points,
        items,
        setItems,
        wallsRef,
        selectedItemIndexes
    });
    /**
     * This Function adds another object by Drag & Drop
     * @param {*DragItem} object
     */
    const handleItemDrag = (object) => {

        if (selectedWall && !draggedItem)
            return;
        const clientX = window.event.clientX;
        const clientY = window.event.clientY;

        let x = (clientX + offsetX - dragX) / scale;
        let y = (-1 * (clientY + offsetY - dragY) / scale);

        setItems((prev) => [...prev,
        {
            ...object,
            x: x || 0,
            y: y || 0,
            height: object.height || 60,
            width: object.width || 60,
            rotation: rotateCache.has(object.key) ? 0 : 180,
            id: generateId(),
            scaleX: 1,
            scaleY: 1,
            category: object.key
        }
        ]);

        const copiedPoints = cloneDeep(points.current);
        const copiedWalls = cloneDeep(wallsRef.current);
        snapshotDispatch({
            type: 'SNAPSHOT', payload: {
                items: [...items, {
                    ...object,
                    x: x || 0,
                    y: y || 0,
                    height: object.height || 60,
                    width: object.width || 60,
                    rotation: rotateCache.has(object.key) ? 0 : 180,
                    id: generateId()
                }], walls: copiedWalls, points: copiedPoints
            }
        });
        //setHist((prev) => [...prev, 'item'])
    }
    /**
     * This Function adds another object by Click
     * @param {*DragItem} object
     */
    const handleItemClick = (object) => {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        const centerX = (screenWidth / 2);
        const centerY = (screenHeight / 2);

        const adjustedX = centerX / scale + offsetX / scale - dragX / scale;
        const adjustedY = centerY / scale + offsetY / scale - dragY / scale;

        setItems((prev) => [...prev,
        {
            ...object,
            x: adjustedX,
            y: -adjustedY,
            height: object.height || 60,
            width: object.width || 60,
            rotation: rotateCache.has(object.key) ? 0 : 180,
            id: generateId(),
            category: object.key,
            scaleX: 1,
            scaleY: 1
        }
        ]);

        const copiedPoints = cloneDeep(points.current);
        const copiedWalls = cloneDeep(wallsRef.current)

        snapshotDispatch({
            type: 'SNAPSHOT', payload: {
                items: [...items, {
                    ...object,
                    x: adjustedX,
                    y: -adjustedY,
                    height: object.height || 60,
                    width: object.width || 60,
                    rotation: rotateCache.has(object.key) ? 0 : 180,
                    id: generateId()
                }], walls: copiedWalls, points: copiedPoints
            }
        });
    }


    const handleItemRotate = () => {
        let newRotation = itemRotator - 90;
        newRotation = newRotation % 360;
        if (newRotation < -180) {
            newRotation = 90;
        }
        let updatedItems = cloneDeep(items);
        let updatedItem = cloneDeep(selectedItem);
        if (updatedItem?.rotation) {
            updatedItem.rotation = newRotation;
        }
        setSelectedItem(updatedItem);
        if (updatedItems[selectedItemIndex]) {
            updatedItems[selectedItemIndex].rotation = newRotation;
        }
        if (shapeRefs.current[selectedItemIndex]) {
            const shape = shapeRefs.current[selectedItemIndex].current;

            if (shape && shape.width && shape.height) {
                //shape.offsetX(shape.width() / 2);
                //shape.offsetY(shape.height() / 2);
                shape.rotation(newRotation);
                //shape.scaleX(1);
                //shape.scaleY(1);
            }
        }
        setItems(updatedItems);
        setItemRotator(newRotation);
    }

    const handleHoverItem = (val = null) => {
        if (val) {
            setHoverItem(val);
        }
    }
    const { user } = useAuthContext();

    const fetchProject = useCallback(async () => {
        const res = await axios.get(config.REACT_APP_API_MAIN_ROUTE + "/projects/user/" + user.userIdMongo + "/project/" + id, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        });
        if (res.data.createdAt === res.data.updatedAt && !res.data.room_type) {
            if (user.helpEnabled) {
                setHelperOpen(true);
            }
            setShowDialog(true);
        }
        if (res.data.room_shape) {
            setWalls(res.data.room_shape);
            wallsRef.current = res.data.room_shape;
        }
        if (res.data.room_items) {
            setItems(res.data.room_items);
        }
        if (res.data.room_points) {
            points.current = res.data.room_points;
        }
        if (res.data.title) {
            setProjectName(res.data.title);
        }
        if (res.data.room_floors) {
            setFloors(res.data.room_floors);
        }
        if (res.data.room_groups) {
            setGroupedItems(res.data.room_groups);
        }
        if (res.data.room_hidden_items) {
            setHiddenItems(res.data.room_hidden_items);
        }
        if (res.data.room_locked_wall) {
            setLockedWallsState(res.data.room_locked_wall);
        }
        if (res.data) {
            const copiedPoints = cloneDeep(res.data.room_points ?? []);
            const copiedWalls = cloneDeep(res.data.room_shape ?? [])
            snapshotDispatch({
                type: 'INITIALIZE_SNAPSHOT', payload: [{
                    items: res.data.room_items ?? [],
                    walls: copiedWalls,
                    points: copiedPoints
                }]
            });
        }

    }, [id]);

    const addPoint = (newPoint) => {
        let tempPoint = cloneDeep(newPoint);
        tempPoint.id = generateId();
        points.current.push(tempPoint);
        return tempPoint;
    };

    const drawLines = useCallback((startPoint, endPoint, id, status) => {
        if (!isDrawingRef.current && !addingJoint.current) {
            return;
        }
        deletedRef.current = false;
        let startPointId = points.current.findIndex(point => point?.x === startPoint?.x && point?.y === startPoint?.y);
        if (startPointId === -1) {
            startPointId = addPoint(startPoint).id;
        }
        else
            startPointId = points.current[startPointId].id;
        let endPointId = points.current.findIndex(point => point?.x === endPoint?.x && point?.y === endPoint?.y);
        if (endPointId === -1) {
            endPointId = addPoint(endPoint).id;
        }
        else
            endPointId = points.current[endPointId].id;
        const newWall = {
            startPointId: startPointId,
            endPointId: endPointId,
            startPoint: startPoint,
            endPoint: endPoint,
            thickness: lineThickness,
            color: lineColor,
            id: id,
            status: status
        };

        setWalls(prevWalls => [...prevWalls, newWall]);

    }, [lineThickness, lineColor]);

    const deleteLastLine = () => {
        setWalls(prev => {
            if (prev?.length === 0)
                return prev;
            const updatedWalls = prev.slice(0, -1);
            return updatedWalls;
        });
        points.current = points.current.filter(point => {
            if (wallsRef.current.findIndex(wall => wall?.startPointId === point?.id || wall?.endPointId === point?.id) === -1)
                return false;
            return true;
        });

    };

    const handleSingleItemDelete = (index) => {
        const updatedItems = cloneDeep(items);
        updatedItems.splice(index, 1); // Remove the item from the array
        // Update the state with the new array
        setItems(updatedItems);
        const selectedShapeRef = shapeRefs.current[index]?.current;
        if (selectedShapeRef) {
            selectedShapeRef.destroy(); // remove the Konva node from the scene
        }
    };

    /**
     * The Undo Function
     */
    const undo = () => {
        if (selectedItem) {
            setSelectedItem(null);
            setSelectedItemIndex(-1);
        }
        snapshotDispatch({ type: 'UNDO' });
        if (snapshot[index - 1]) {
            const copiedPoints = cloneDeep(snapshot[index - 1].points);
            const copiedWalls = cloneDeep(snapshot[index - 1].walls);
            const copiedItems = cloneDeep(snapshot[index - 1].items);
            setItems(copiedItems); // Update items to the previous snapshot's items
            setWalls(copiedWalls);
            wallsRef.current = copiedWalls;
            points.current = copiedPoints;
        }
    }
    /**
     * Redo Function
     */
    const redo = () => {
        snapshotDispatch({ type: 'REDO' });
        if (snapshot[index + 1]) {
            const copiedPoints = cloneDeep(snapshot[index + 1].points);
            const copiedWalls = cloneDeep(snapshot[index + 1].walls);
            const copiedItems = cloneDeep(snapshot[index + 1].items);
            setItems(copiedItems); // Update items to the previous snapshot's items
            setWalls(copiedWalls);
            wallsRef.current = copiedWalls;
            points.current = copiedPoints;
        }
    }






    useEffect(() => {
        const handleKeyPress = (event) => {
            if (selectedItem && (event.key === 'Backspace' || event.key === 'Delete') && !onPanel) {
                handleObjectDeletion();
                setSelectedItem(null);
                setSelectedItemIndexes([]);
                setSelectedItemIndex(-1);
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };

    }, [selectedItem, selectedItemIndex, onPanel, selectedItemIndexes]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (selectedWall !== null && (event.key === 'Backspace' || event.key === 'Delete') && !onPanel) {
                setDeleteState(true);
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [selectedWall, onPanel]);

    useEffect(() => {
        const handleClickPos = (event) => {
            let { clientX, clientY } = event;

            if (event.type === 'touchstart' || event.type === 'touchmove' || event.type === 'touchend') {
                // Handle touch events
                const touch = event.touches[0] || event.changedTouches[0]; // First touch point
                clientX = touch.clientX;
                clientY = touch.clientY;
            } else {
                // Handle mouse events
                clientX = event.clientX;
                clientY = event.clientY;
            }

            let x = (clientX + offsetX - dragX) / scale;
            let y = (-1 * (clientY + offsetY - dragY) / scale);
            setClickedPos({ x: x, y: y })
        };

        window.addEventListener('mousedown', handleClickPos);
        window.addEventListener('touchstart', handleClickPos);


        return () => {
            window.removeEventListener('mousedown', handleClickPos);
            window.removeEventListener('touchstart', handleClickPos);
        };
    }, [dragX, dragY, scale]);

    useEffect(() => {
        const handleKeyPressUndoRedo = (event) => {
            if (event.ctrlKey && event.shiftKey && (event.key === 'z' || event.key === 'Z')) {
                redo();
            }
            else if (event.ctrlKey && event.shiftKey && (event.key === 'g' || event.key === 'G')) {
                if (groupedItems.length > 0) {
                    event.preventDefault();
                    handleUngroup();
                }
            }
            else if (event.ctrlKey && (event.key === 'z' || event.key === 'Z')) {
                undo();
            }
            else if (event.ctrlKey && (event.key === 'g' || event.key === 'G')) {
                if (selectedItemIndexes.length > 0) {
                    event.preventDefault();
                    handleGroup();
                }
            }

            else {
                if ((event.key === 'd' || event.key === 'D') && !onPanel) {
                    setTopBarMode('Draw')
                }
                if ((event.key === 's' || event.key === 'S') && !onPanel) {
                    setTopBarMode('Hand')
                }

            }
        };

        window.addEventListener('keydown', handleKeyPressUndoRedo);

        return () => {
            window.removeEventListener('keydown', handleKeyPressUndoRedo);
        };

    });

    const deleteWallsWithPoint = (pointId, newCoordinates) => {
        const updatedWalls = walls.map(wall => {
            if (wall.startPointId === pointId) {
                return null;
            } else if (wall.endPointId === pointId) {
                return null;
            }
            return wall;
        });
        setWalls(updatedWalls);
    };

    const distance = (startPoint, endPoint) => {
        return Math.hypot(startPoint?.x - endPoint?.x, startPoint?.y - endPoint?.y);
    };

    const updatePoint = (id, newPoint) => {
        let index = points.current.findIndex(point => point?.id === id);
        if (index === -1 || !newPoint?.x)
            return;
        const oldPoint = points.current[index];
        points.current[index].x = newPoint.x;
        points.current[index].y = newPoint.y;
        index = points.current.findIndex(point => point?.id === id);
        if (index === -1)
            return;

        points.current[index].x = newPoint.x;
        points.current[index].y = newPoint.y;
        setWalls([...walls]);
    };

    useEffect(() => {
        if (prevTopBarMode.current === "Draw" && topBarMode === "Draw") {
            isDrawingRef.current = false;
            setIsDrawing(false)
            setLineDrawing(false);
        }
        prevTopBarMode.current = topBarMode;
    }, [topBarMode])

    // Effect to update line color and thickness

    const [finishedRef, setFinishedRef] = useState(false);
    const lastDeletedRef = useRef(undefined);

    useEffect(() => {
        if (deleteState && selectedWall !== undefined) {
            setDeleteState(false);
            setSelectedFloor(null);
            wallsRef.current[selectedWall] = null;

            const copiedPoints = cloneDeep(points.current);
            const copiedWalls = cloneDeep(wallsRef.current);

            setWalls(prevWalls => {
                const updatedWalls = cloneDeep(prevWalls);
                const wallToUpdate = updatedWalls[selectedWall];
                if (wallToUpdate) {
                    wallToUpdate.startPointId = null;
                    wallToUpdate.endPointId = null;
                }
                return updatedWalls;
            });
            setSelectedWall(undefined);
            lastDeletedRef.current = selectedWall;
            snapshotDispatch({
                type: 'SNAPSHOT', payload: {
                    items: items,
                    walls: copiedWalls,
                    points: copiedPoints
                }
            })
        }
        else {
            setDeleteState(false);
        }
    }, [deleteState, selectedWall, setWalls, onPanel]);

    const [newSelected, setNewSelected] = useState(false);


    useEffect(() => {
        if (selectedWall === undefined || !newSelected && !moving)
            return;

        setNewSelected(false);
        const wall = walls[selectedWall];
        const tempWall = selectedWall;
        if (wall?.color && wall?.thickness) {
            setLineColor(undefined);
            setLineThickness(undefined);
            setLineLength(undefined);
            const startPointIndex = points.current.findIndex(point => point?.id === wall?.startPointId);
            const startPoint = startPointIndex === -1 ? null : points.current[startPointIndex];
            const endPointIndex = points.current.findIndex(point => point?.id === wall?.endPointId);
            const endPoint = endPointIndex === -1 ? null : points.current[endPointIndex];
            const length = distance(startPoint, endPoint);

            setLineLength(length);
            setLineColor(wall.color);
            setLineThickness(wall.thickness);
            setFinishedRef(true);
        }
    }, [selectedWall, points, setSelectedWall, walls, newSelected, moving, items]);

    useEffect(() => {
        if (selectedWall !== undefined && lineColor !== undefined && lineThickness !== undefined && lineLength !== undefined && finishedRef && !moving) {
            var tempLength = Math.max(lineLength, 1);
            const wall = walls[selectedWall];
            const startPointIndex = points.current.findIndex(point => point?.id === wall?.startPointId);
            const startPoint = startPointIndex === -1 ? null : points.current[startPointIndex];
            const endPointIndex = points.current.findIndex(point => point?.id === wall?.endPointId);
            let endPoint = endPointIndex === -1 ? null : points.current[endPointIndex];
            if (startPoint && endPoint) {
                const currentLength = distance(startPoint, endPoint);
                const angle = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);
                const newEndPointX = startPoint.x + tempLength * Math.cos(angle);
                const newEndPointY = startPoint.y + tempLength * Math.sin(angle);
                updatePoint(wall.endPointId, { x: newEndPointX, y: newEndPointY });
                /**
                 * Update Line Thickness and Line Color
                 */
                let updatedWalls = cloneDeep(walls);
                updatedWalls[selectedWall].color = lineColor;
                updatedWalls[selectedWall].thickness = lineThickness;

                setWalls(updatedWalls);
                wallsRef.current = updatedWalls;
            }
        }
    }, [selectedWall, lineLength, lineColor, lineThickness, selecting, moving]);

    useEffect(() => {
        if (deleteWallId !== null) {
            var tempInd = walls.findIndex(wall => wall?.id == deleteWallId);
            setWalls(prevWalls => {
                const updatedWalls = [...prevWalls];
                const wallToUpdate = updatedWalls[tempInd];
                if (wallToUpdate) {
                    wallToUpdate.startPointId = null;
                    wallToUpdate.endPointId = null;
                }
                return updatedWalls;
            });
            setDeleteWallId(null);
        }
    }, [deleteWallId, walls, setWalls]);

    const deleteWallsWithId = useCallback((id) => {
        {
            var tempInd = wallsRef.current.findIndex(wall => wall?.id === id);
            wallsRef.current[tempInd] = null;
            setDeleteWallId(id);
        }

    });

    useEffect(() => {
        points.current = points.current.filter(point => {
            if (wallsRef.current.findIndex(wall => wall?.startPointId === point?.id || wall?.endPointId === point?.id) === -1)
                return false;
            return true;
        });
    }, [wallsRef.current]);
    const getPoint = (id) => {
        let index = points.current.findIndex(point => point?.id === id);
        if (index === -1)
            return null;
        return points.current[index];
    }
    const distancePointToLine = (point, linePoint1, linePoint2) => {
        if (!linePoint1?.x || !linePoint2?.x || !point?.x)
            return;
        var lineSegmentX = linePoint2.x - linePoint1.x;
        var lineSegmentY = linePoint2.y - linePoint1.y;
        var lineLengthSquared = lineSegmentX * lineSegmentX + lineSegmentY * lineSegmentY;
        var pointVectorX = point.x - linePoint1.x;
        var pointVectorY = point.y - linePoint1.y;
        var dotProduct = (pointVectorX * lineSegmentX) + (pointVectorY * lineSegmentY);
        var t = Math.max(0, Math.min(1, dotProduct / lineLengthSquared));
        var closestPointX = linePoint1.x + t * lineSegmentX;
        var closestPointY = linePoint1.y + t * lineSegmentY;
        var distance = Math.sqrt((point.x - closestPointX) * (point.x - closestPointX) + (point.y - closestPointY) * (point.y - closestPointY));
        return distance;
    }
    const closestPointOnLine = (point, linePoint1, linePoint2) => {
        if (!linePoint1?.x || !linePoint2?.x)
            return;

        var lineSegmentX = linePoint2.x - linePoint1.x;
        var lineSegmentY = linePoint2.y - linePoint1.y;
        var lineLengthSquared = lineSegmentX * lineSegmentX + lineSegmentY * lineSegmentY;

        var pointVectorX = point.x - linePoint1.x;
        var pointVectorY = point.y - linePoint1.y;

        var dotProduct = (pointVectorX * lineSegmentX) + (pointVectorY * lineSegmentY);
        var t = Math.max(0, Math.min(1, dotProduct / lineLengthSquared));

        var closestPointX = linePoint1.x + t * lineSegmentX;
        var closestPointY = linePoint1.y + t * lineSegmentY;

        return { x: closestPointX, y: closestPointY };
    }


    const getWall = (id) => {
        let index = wallsRef.current.findIndex(wall => wall?.id === id);
        if (index == -1)
            return null;
        return wallsRef.current[index];
    }

    useEffect(() => {
        let lockingMechanism = true;
        const isBetween = (point, point1, point2) => {
            if (point.x - Math.max(point1.x, point2.x) > 10)
                return false;
            if (point.y - Math.max(point1.y, point2.y) > 10)
                return false;
            if (Math.min(point1.x, point2.x) - point.x > 10)
                return false;
            if (Math.min(point1.y, point2.y) - point.y > 10)
                return false;
            return true;
        }



        const getWallId = (point) => {

            let index = wallsRef.current.findIndex(wall => {
                if (!wall || !wall.startPointId || !wall.endPointId)
                    return false;
                let point1 = getPoint(wall.startPointId);
                let point2 = getPoint(wall.endPointId);
                // Apply for Rotation
                const radians = transform.degree * (Math.PI / 180); // Convert the angle to radians
                const cos = Math.cos(radians);
                const sin = Math.sin(radians);

                // Translate point back to origin (center of the wall)
                let rotatedPoint1 = {
                    x: point1?.x * cos - point1?.y * sin,
                    y: point1?.x * sin + point1?.y * cos
                }

                let rotatedPoint2 = {
                    x: point2?.x * cos - point2?.y * sin,
                    y: point2?.x * sin + point2?.y * cos
                }



                if (point1?.x && point2?.x && distancePointToLine(point, rotatedPoint1, rotatedPoint2) < 10) {
                    if (isBetween(point, rotatedPoint1, rotatedPoint2))
                        return true;
                }
                return false;
            });
            if (index === -1) {
                return undefined;
            }
            return index;
        }
        const deletePoint = (id) => {
            deleteWallsWithPoint(id);
            let index = points.current.findIndex(point => point?.id === id);
            if (index === -1)
                return;
            points.current[index] = null;
            index = points.current.findIndex(point => point?.id === id);
            if (index === -1)
                return;
            points.current[index] = null;
        }


        const addLocalPoint = (newPoint) => {
            newPoint.id = generateId();
            points.current.push(newPoint);
            return newPoint;
        };
        const splitWalls = (id1, id2) => {
            const wall1 = getWall(id1);
            const wall2 = getWall(id2);
            if (!wall1 || !wall2)
                return;

            if (wall2.startPointId == wall1.startPointId || wall2.startPointId == wall1.endPointId) {
                const oldPoint = getPoint(wall2.startPointId);
                const newPoint = addPoint(oldPoint);
                wall2.startPointId = newPoint.id;
                wall2.startPoint = newPoint;
            }

            else if (wall2.endPointId == wall1.startPointId || wall2.endPointId == wall1.endPointId) {
                const oldPoint = getPoint(wall2.endPointId);
                const newPoint = addPoint(oldPoint);
                wall2.endPointId = newPoint.id;
                wall2.endPoint = newPoint;
            }

        };
        const findInterception = (point1, point2, point3, point4, newPoint) => {
            let x1 = point1?.x;
            let y1 = point1?.y;
            let x2 = point2?.x;
            let y2 = point2?.y;
            let x3 = point3?.x;
            let y3 = point3?.y;
            let x4 = point4?.x;
            let y4 = point4?.y;
            var slope1 = (x2 - x1) !== 0 ? (y2 - y1) / (x2 - x1) : Infinity;
            var slope2 = (x4 - x3) !== 0 ? (y4 - y3) / (x4 - x3) : Infinity;
            if (slope1 === slope2 && slope1 === Infinity) {
                return newPoint;
            }
            let intersectionPoint = {
                x: 0,
                y: 0,
            };
            if (slope1 === Infinity) {
                intersectionPoint.x = x1;
                intersectionPoint.y = slope2 * (x1 - x3) + y3;
            } else if (slope2 === Infinity) {
                intersectionPoint.x = x3;
                intersectionPoint.y = slope1 * (x3 - x1) + y1;
            }
            else if (slope1 == slope2) {
            } else {

                intersectionPoint.x = (y3 - y1 + slope1 * x1 - slope2 * x3) / (slope1 - slope2);
                intersectionPoint.y = slope1 * (intersectionPoint.x - x1) + y1;
            }
            return intersectionPoint;
        }

        const updateLine = (currWall, newPoint, initialPoint) => {
            if (!wallsRef.current[currWall]?.startPointId || !wallsRef.current[currWall]?.endPointId)
                return;
            let id = wallsRef.current[currWall].id;
            let index1 = wallsRef.current.findIndex((wall, index) => index != currWall && ((wall?.endPointId === wallsRef.current[currWall].startPointId) || (wall?.startPointId === wallsRef.current[currWall].startPointId)));
            let index2 = wallsRef.current.findIndex((wall, index) => index != currWall && ((wall?.startPointId === wallsRef.current[currWall].endPointId) || (wall?.endPointId === wallsRef.current[currWall].endPointId)));
            let id1 = wallsRef.current[index1]?.id;
            let id2 = wallsRef.current[index2]?.id;
            let point1 = getPoint(wallsRef.current[currWall].startPointId);
            let point2 = getPoint(wallsRef.current[currWall].endPointId);

            if (!point1 || !point2)
                return;
            if (index1 === -1 || index2 === -1) {
                point1.x += newPoint.x - initialPoint.x;
                point1.y += newPoint.y - initialPoint.y;
                point2.x += newPoint.x - initialPoint.x;
                point2.y += newPoint.y - initialPoint.y;
                updatePoint(point1.id, point1);
                updatePoint(point2.id, point2);
                return;
            }
            let point3 = getPoint(wallsRef.current[index1].startPointId);
            let point4 = getPoint(wallsRef.current[index1].endPointId);
            let point5 = getPoint(wallsRef.current[index2].startPointId);
            let point6 = getPoint(wallsRef.current[index2].endPointId);
            if(wallsRef.current[index1].startPointId === wallsRef.current[currWall].startPointId)
            {
                let tempPoint = point3;
                point3 = point4;
                point4 = tempPoint;
            }
            if(wallsRef.current[index2].endPointId === wallsRef.current[currWall].endPointId)
            {
                let tempPoint = point5;
                point5 = point6;
                point6 = tempPoint;
            }

            if (!point3 || !point4 || !point5 || !point6) {
                point1.x += newPoint.x - initialPoint.x;
                point1.y += newPoint.y - initialPoint.y;
                point2.x += newPoint.x - initialPoint.x;
                point2.y += newPoint.y - initialPoint.y;
                updatePoint(point1.id, point1);
                updatePoint(point2.id, point2);
                return;
            }
            let newPoint1 = {
                x: point1.x + newPoint.x - initialPoint.x,
                y: point1.y + newPoint.y - initialPoint.y
            };
            let newPoint2 = {
                x: point2.x + newPoint.x - initialPoint.x,
                y: point2.y + newPoint.y - initialPoint.y,
            }
            var slope1 = (newPoint2.x - newPoint1.x) !== 0 ? (newPoint2.y - newPoint1.y) / (newPoint2.x - newPoint1.x) : Infinity;
            var slope2 = (point4.x - point3.x) !== 0 ? (point4.y - point3.y) / (point4.x - point3.x) : Infinity;



            if (Math.abs(slope1 - slope2) < 0.1) {
                splitWalls(id, id1);
                return;
            }
            slope1 = (newPoint2.x - newPoint1.x) !== 0 ? (newPoint2.y - newPoint1.y) / (newPoint2.x - newPoint1.x) : Infinity;
            slope2 = (point6.x - point5.x) !== 0 ? (point6.y - point5.y) / (point6.x - point5.x) : Infinity;
            if (Math.abs(slope1 - slope2) < 0.1) {
                splitWalls(id, id2);
                return;
            }

            let intersectionPoint1 = findInterception(newPoint1, newPoint2, point3, point4, newPoint1);
            let intersectionPoint2 = findInterception(newPoint1, newPoint2, point5, point6, newPoint2);
            // console.log(intersectionPoint1,point3,point4);
            // console.log(intersectionPoint2,point5,point6);
            if((Math.abs(intersectionPoint1.x - point3.x)<5 && Math.abs(intersectionPoint1.y - point3.y)<5) || (intersectionPoint1.x < point3.x) != (point1.x < point3.x)){
                return;
            }
            if((Math.abs(intersectionPoint2.x - point6.x)<5 && Math.abs(intersectionPoint2.y - point6.y)<5) || (intersectionPoint2.x < point6.x) != (point2.x < point6.x)){
                return;
            }
            if((Math.abs(intersectionPoint1.x - intersectionPoint2.x)<5 && Math.abs(intersectionPoint1.y - intersectionPoint2.y)<5) || (intersectionPoint1.x < intersectionPoint2.x) != (point1.x < point2.x)){
                return;
            }
            updatePoint(point1.id, intersectionPoint1);
            updatePoint(point2.id, intersectionPoint2);
            // updatePoint(point1.id, newPoint1);
            // updatePoint(point2.id, newPoint2);
        }
        const deleteLocalLastLine = () => {
            const updatedWalls = wallsRef.current.slice(0, -1);

            wallsRef.current = updatedWalls;
            wallsRef.current = wallsRef.current.filter(wall => {
                if (!wall?.startPointId || !wall?.endPointId)
                    return false;
                return true;
            });
            points.current = points.current.filter(point => {
                if (wallsRef.current.findIndex(wall => wall?.startPointId == point?.id || wall?.endPointId == point?.id) == -1)
                    return false;
                return true;
            });

        };
        const drawLocalLines = (startPoint, endPoint, id) => {
            if (!isDrawingRef.current && !addingJoint.current) {
                return;
            }
            deletedRef.current = false;
            let startPointId = points.current.findIndex(point => point?.x === startPoint?.x && point?.y === startPoint?.y);
            if (startPointId === -1) {
                startPointId = addPoint(startPoint).id;
            }
            else
                startPointId = points.current[startPointId].id;
            let endPointId = points.current.findIndex(point => point?.x === endPoint?.x && point?.y === endPoint?.y);
            if (endPointId === -1) {
                endPointId = addPoint(endPoint).id;
            }
            else
                endPointId = points.current[endPointId].id;
            const newWall = {
                startPointId: startPointId,
                endPointId: endPointId,
                startPoint: startPoint,
                endPoint: endPoint,
                thickness: lineThickness,
                color: lineColor,
                id: id,
            };
            const updatedWalls = [
                ...wallsRef.current, newWall
            ];

            wallsRef.current = updatedWalls;

        };

        const findClosestPoint = (currPoint, currClose, drawing) => {

            let closestPoint = null;
            let closestDistance = Infinity;
            if (!currClose)
                currClose = currPointRef.current;
            wallsRef.current?.forEach((wall, index) => {
                if ((!drawing || index !== wallsRef.current?.length - 1) && wall) {
                    let startPointId = points.current.findIndex(point => point?.id === wall.startPointId);
                    let startPoint = points.current[startPointId];
                    let endPointId = points.current.findIndex(point => point?.id === wall.endPointId);
                    let endPoint = points.current[endPointId];
                    const startDistance = distance(currClose, startPoint);
                    const endDistance = distance(currClose, endPoint);
                    if (startPoint?.id !== currPoint?.id && startDistance < closestDistance) {
                        closestPoint = startPoint;
                        closestDistance = startDistance;
                    }
                    if (endPoint?.id !== currPoint?.id && endDistance < closestDistance) {
                        closestPoint = endPoint;
                        closestDistance = endDistance;
                    }
                }
            });

            if (closestDistance > 8 / scale) {
                closestPoint = null;
                closestDistance = Infinity;
            }
            if (closestPoint !== null && closestPoint.id !== currPoint) {
                return closestPoint;
            }
            closestDistance = Infinity;
            let closestWall = undefined;
            let tempX, tempY;
            let tempWallId = undefined;
            wallsRef.current?.forEach((wall, wallIndex) => {
                if ((!drawing || wallIndex !== wallsRef.current?.length - 1) && wall?.startPointId && wall?.endPointId && wall?.startPointId !== currPoint && wall?.endPointId !== currPoint) {
                    let startPoint = points.current[points.current.findIndex(point => point?.id === wall.startPointId)];
                    let endPoint = points.current[points.current.findIndex(point => point?.id === wall.endPointId)];
                    if (distancePointToLine(currClose, startPoint, endPoint) < closestDistance && distancePointToLine(currClose, startPoint, endPoint) < 8 / scale) {
                        closestWall = wallIndex;
                        closestDistance = distancePointToLine(currClose, startPoint, endPoint);
                        closestPoint = closestPointOnLine(currClose, startPoint, endPoint);
                        tempWallId = wall.id;
                    }
                }
            });
            if (closestPoint && closestWall !== undefined) {
                closestPoint.wall = closestWall;
                closestPoint.wallId = tempWallId
                closestPoint.id = -1;

                return closestPoint;
            }
            let closestX = null;
            let closestXDistance = Infinity;
            let closestY = null;
            let closestYDistance = Infinity;
            wallsRef.current?.forEach((wall, wallIndex) => {
                if (wall?.startPointId && wall?.endPointId && wallIndex !== wallsRef.current.length - 1) {
                    let startPoint = points.current[points.current.findIndex(point => point?.id === wall.startPointId)];
                    let endPoint = points.current[points.current.findIndex(point => point?.id === wall.endPointId)];
                    if (Math.abs(currClose.x - startPoint?.x) < closestXDistance && Math.abs(currClose.x - startPoint?.x) > 0) {
                        closestXDistance = Math.abs(currClose.x - startPoint.x);
                        closestX = startPoint.x;
                    }
                    if (Math.abs(currClose.x - endPoint?.x) < closestXDistance && Math.abs(currClose.x - endPoint?.x) > 0) {
                        closestXDistance = Math.abs(currClose.x - endPoint.x);
                        closestX = endPoint.x;
                    }

                    if (Math.abs(currClose.y - startPoint?.y) < closestYDistance && Math.abs(currClose.y - startPoint?.y) > 0) {
                        closestYDistance = Math.abs(currClose.y - startPoint.y);
                        closestY = startPoint.y;
                    }
                    if (Math.abs(currClose.y - endPoint?.y) < closestYDistance && Math.abs(currClose.y - endPoint?.y) > 0) {
                        closestYDistance = Math.abs(currClose.y - endPoint.y);
                        closestY = endPoint.y;
                    }
                }
            }
            );

            closestPoint = currClose;
            if (Math.min(closestXDistance, closestYDistance) > 10 / scale)
                return null;
            if (closestXDistance < closestYDistance)
                closestPoint.x = closestX;
            else
                closestPoint.y = closestY;
            closestPoint.id = -1;
            return closestPoint;
        };

        const addJoint = (point, wall) => {
            let tempSelectedWallId = getWallId(point);
            if (wall !== undefined)
                tempSelectedWallId = wall;
            const tempWall = getWall(tempSelectedWallId);
            const tempId1 = generateId();
            const tempId2 = generateId();
            //const tempId3 = generateId();
            if (!tempWall?.startPointId) {
                return;
            }
            addingJoint.current = true;
            drawLines(getPoint(tempWall.startPointId), point, tempId1, "Done");
            drawLines(getPoint(tempWall.endPointId), point, tempId2, "Done");
            drawLocalLines(getPoint(tempWall.startPointId), point, tempId1);
            drawLocalLines(getPoint(tempWall.endPointId), point, tempId2);
            deleteWallsWithId(tempSelectedWallId, true);

            addingJoint.current = false;
        }
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                console.log("Escape pressed");
                if (isDrawingRef.current === true) {
                    deleteLocalLastLine();
                }

                setIsDrawing(false);
                isDrawingRef.current = false;
            }
            if (event.key === 'Shift') {
                lockingMechanism = false;
            }
            if ((event.key === 'Control' || event.key === 'Meta') && topBarMode === 'Draw' && !onPanel) {
                //setTopBarMode('Hand');
                setSelecting(true);
                controlPressedRef.current = true;
            }
        }
        const handleKeyUp = (event) => {
            if (event.key === 'Shift') {
                lockingMechanism = true;
            }
            if ((event.key === 'Control' || event.key === 'Meta') && topBarMode === 'Hand' && controlPressedRef.current && !onPanel) {
                //setTopBarMode('Draw');
                controlPressedRef.current = false;
            }
        }

        /**
         * Common Logic
         * for Drawing Line Start
         */

        const handleDrawLineStart = (clientX, clientY, button) => {
            if (button === 1) { // middle mouse button
                deleteLastLine();
                deleteLocalLastLine();
                isDrawingRef.current = false;
                //setHist((prev) => prev.slice(0, -1));
            }
            else if (topBarMode === 'Draw') {
                setIsDrawing(true);

                let endPoint = {
                    x: (clientX + offsetX - dragX) / scale,
                    y: (-1 * (clientY + offsetY - dragY) / scale),
                };

                // This is For Rotated Drawing Lines
                const radians = degree * (Math.PI / 180); // Convert the angle to radians
                const cos = Math.cos(radians);
                const sin = Math.sin(radians);

                // Translate point back to origin (center of the wall)
                const translatedX = endPoint.x;
                const translatedY = endPoint.y;

                // Apply rotation
                let rotatedX = translatedX;
                let rotatedY = translatedY;
                if (degree === 180) {
                    rotatedX = translatedX * cos - translatedY * sin;
                    rotatedY = (translatedX * sin + translatedY * cos);
                }
                else if (degree === 90 || degree === 270) {
                    rotatedX = -(translatedX * cos - translatedY * sin);
                    rotatedY = -(translatedX * sin + translatedY * cos);
                }
                endPoint.x = rotatedX;
                endPoint.y = rotatedY;

                var flag = false;
                if (isDrawingRef.current === false || startPointRef.current === null) {
                    startPointRef.current = endPoint;
                    flag = true;
                    isDrawingRef.current = true;
                    setIsDrawing(true);
                }
                if (Math.abs(endPoint.x - startPointRef.current.x) / Math.abs(endPoint.y - startPointRef.current.y) < 1 / 20)
                    endPoint.x = startPointRef.current.x
                if (Math.abs(endPoint.y - startPointRef.current.y) / Math.abs(endPoint.x - startPointRef.current.x) < 1 / 20)
                    endPoint.y = startPointRef.current.y
                currPointRef.current = endPoint;
                const closestPoint = findClosestPoint();

                if (!flag) {
                    deleteLastLine();
                    deleteLocalLastLine();
                }
                if (flag) {
                    startPointRef.current = endPoint
                }
                if (lockingMechanism === true) {
                    if (closestPoint) {

                        endPoint = closestPoint;
                        if (closestPoint.wallId !== undefined) {
                            addJoint(closestPoint, closestPoint.wallId);
                        }
                    }
                }

                var tempId = generateId();
                if (flag) {
                    startPointRef.current = endPoint
                }
                setIsDrawing(true);
                if (startPointRef.current !== endPoint) {
                    drawLines(startPointRef.current, endPoint, tempId, "Done");
                    drawLocalLines(startPointRef.current, endPoint, tempId);
                }
                startPointRef.current = endPoint;
                tempId = generateId();
                drawLines(endPoint, endPoint, tempId, "Done");

                drawLocalLines(endPoint, endPoint, tempId);
                const copiedPoints = cloneDeep(points.current);
                const copiedWalls = cloneDeep(wallsRef.current);
                if (!flag) {
                    snapshotDispatch({
                        type: 'SNAPSHOT', payload: {
                            items: items,
                            walls: copiedWalls,
                            points: copiedPoints
                        }
                    })
                }
                window.addEventListener('mousemove', handleLineMouseMove)
            }
        };

        const handleLineMouseDown = (event) => {
            const { clientX, clientY, button } = event;
            handleDrawLineStart(clientX, clientY, button);
        }

        const handleLineTouchStart = (event) => {
            let { clientX, clientY, button } = event.touches[0];
            handleDrawLineStart(clientX, clientY, button = 0);
        }

        /**
         * Common Logic for
         * for Drawing Line Move
         */
        const handleDrawLineMove = (clientX, clientY) => {
            if (topBarMode === 'Draw' && isDrawingRef.current && startPointRef.current?.x) {
                let endPoint = {
                    x: (clientX + offsetX - dragX) / scale,
                    y: (-1 * (clientY + offsetY - dragY) / scale),
                };
                // This is For Rotated Drawing Lines
                const radians = degree * (Math.PI / 180); // Convert the angle to radians

                const cos = Math.cos(radians);
                const sin = Math.sin(radians);

                // Translate point back to origin (center of the wall)
                const translatedX = endPoint.x;
                const translatedY = endPoint.y;

                // Apply rotation
                // Apply rotation
                let rotatedX = translatedX;
                let rotatedY = translatedY;
                if (degree === 180) {
                    rotatedX = translatedX * cos - translatedY * sin;
                    rotatedY = (translatedX * sin + translatedY * cos);
                }
                else if (degree === 90 || degree === 270) {
                    rotatedX = -(translatedX * cos - translatedY * sin);
                    rotatedY = -(translatedX * sin + translatedY * cos);
                }

                endPoint.x = rotatedX;
                endPoint.y = rotatedY;

                setCursorPosition(endPoint);
                if (lockingMechanism === true) {
                    if (Math.abs(endPoint.x - startPointRef.current.x) / Math.abs(endPoint.y - startPointRef.current.y) < 1 / 20)
                        endPoint.x = startPointRef.current.x
                    if (Math.abs(endPoint.y - startPointRef.current.y) / Math.abs(endPoint.x - startPointRef.current.x) < 1 / 20)
                        endPoint.y = startPointRef.current.y
                    currPointRef.current = endPoint;
                    const closestPoint = findClosestPoint(undefined, undefined, true);
                    if (closestPoint !== null)
                        endPoint = closestPoint;
                }
                deleteLastLine();
                deleteLocalLastLine();

                const tempId = generateId();

                drawLocalLines(startPointRef.current, endPoint, tempId);
                drawLines(startPointRef.current, endPoint, tempId, "Drawing");
            }
        };

        const handleLineMouseMove = (event) => {
            const { clientX, clientY } = event;
            handleDrawLineMove(clientX, clientY);
        };

        const handleLineTouchMove = (event) => {
            const { clientX, clientY } = event.touches[0];
            handleDrawLineMove(clientX, clientY);
        }

        const handleLineMouseUp = (event) => {
        };

        let selectedPointId = null;
        let selectedWallId = undefined;
        let initialPoint = null;
        let movedWall = false;
        let jointPoint = null;
        let jointWall = null;

        /**
         * The Common Logic of Select Mouse Down
         */
        const handleSelectDown = (clientX, clientY) => {
            movedWall = false;
            if (!selecting)
                return;
            selectedPointId = null;
            setClickedItem(null);

            const click = {
                x: (clientX + offsetX - dragX) / scale,
                y: (-1 * (clientY + offsetY - dragY) / scale),
            };

            currPointRef.current = click;
            const clickPoint = findClosestPoint();
            setMouseOnItemsCheck(clickPoint);
            if (clickPoint && clickPoint?.id !== -1 && !selectedItemRef.current && !lockedWallsState && points) {
                let index = points.current.findIndex(point => point?.x === clickPoint?.x && point?.y === clickPoint?.y);
                if (index === -1)
                    return;
                selectedPointId = points.current[index].id;
            }
            else if (!selectedItemRef.current && !lockedWallsState) {
                initialPoint = click;
                selectedWallId = getWallId(click);
            }

            if (selectedWallId !== undefined && !lockedWallsState) {
                setMoving(true);
                setFinishedRef(false);
                setNewSelected(true);
                setSelectedWall(selectedWallId);
            }
            else {
                setMoving(false);
                setNewSelected(false);
                setSelectedWall(undefined);
            }

        }
        const handleSelectMouseDown = (event) => {
            const { clientX, clientY } = event;
            handleSelectDown(clientX, clientY);
        };
        const handleSelectTouchStart = (event) => {
            const { clientX, clientY } = event.touches[0];
            handleSelectDown(clientX, clientY);
        };

        /**
         * The Common Logic of Select Mouse Move
         */

        const handleSelectMove = (clientX, clientY) => {
            const newPoint = {
                x: (clientX + offsetX - dragX) / scale,
                y: (-1 * (clientY + offsetY - dragY) / scale),
            };
            const click = {
                x: newPoint.x,
                y: newPoint.y,
            }
            currPointRef.current = click;
            let clickPoint = findClosestPoint();
            if (selectedPointId || selectedWallId !== undefined)
                setCursorType('pointer');
            else if (clickPoint && clickPoint?.id !== -1) {

                let index = points.current.findIndex(point => point?.x === clickPoint?.x && point?.y === clickPoint?.y);
                if (index !== -1) {
                    setCursorType('pointer');
                }
            }
            else {
                if (getWallId(click) !== undefined) {
                    setCursorType('pointer');
                }
                else
                    setCursorType('default');
            }
            if (selectedPointId) {
                clickPoint = findClosestPoint(selectedPointId);
                if (lockingMechanism && clickPoint) {
                    updatePoint(selectedPointId, clickPoint);
                }
                else
                    updatePoint(selectedPointId, newPoint);
                if (clickPoint?.wallId !== undefined) {
                    jointPoint = cloneDeep(clickPoint);
                    jointWall = clickPoint?.wallId;
                }
                else {
                    jointPoint = null;
                    jointWall = null
                }
                movedWall = true;
            }
            if (selectedWallId !== undefined && !selectedItem) {

                setCursorType("Move")
                // Apply for Rotation
                const radians = transform.degree * (Math.PI / 180); // Convert the angle to radians
                const cos = Math.cos(radians);
                const sin = Math.sin(radians);

                // Translate point back to origin (center of the wall)
                let newPointUpdated = newPoint;
                let initialPointUpdated = initialPoint;

                if (degree === 180) {
                    newPointUpdated = {
                        x: newPoint?.x * cos - newPoint?.y * sin,
                        y: newPoint?.x * sin + newPoint?.y * cos
                    };
                    initialPointUpdated = {
                        x: initialPoint?.x * cos - initialPoint?.y * sin,
                        y: initialPoint?.x * sin + initialPoint?.y * cos
                    }
                }

                else if (degree === 90 || degree === 270) {
                    newPointUpdated = {
                        x: -(newPoint?.x * cos - newPoint?.y * sin),
                        y: -(newPoint?.x * sin + newPoint?.y * cos)
                    };
                    initialPointUpdated = {
                        x: -(initialPoint?.x * cos - initialPoint?.y * sin),
                        y: -(initialPoint?.x * sin + initialPoint?.y * cos)
                    }
                }

                updateLine(selectedWallId, newPointUpdated, initialPointUpdated);
                movedWall = true;
                initialPoint = newPoint;

                currPointRef.current = {
                    x: getPoint(wallsRef.current[selectedWallId].startPointId).x,
                    y: getPoint(wallsRef.current[selectedWallId].startPointId).y,
                }

                // clickPoint = findClosestPoint(wallsRef.current[selectedWallId].startPointId);
                // let flag = false
                // if (clickPoint?.wallId !== undefined) {
                //     updatePoint(wallsRef.current[selectedWallId].startPointId, clickPoint);
                //     jointPoint = cloneDeep(clickPoint);
                //     jointWall = clickPoint?.wallId;
                //     flag = true;
                // }

                // currPointRef.current = {
                //     x: getPoint(wallsRef.current[selectedWallId].endPointId).x,
                //     y: getPoint(wallsRef.current[selectedWallId].endPointId).y,
                // }

                // clickPoint = findClosestPoint(wallsRef.current[selectedWallId].endPointId);

                // if (clickPoint?.wallId !== undefined) {
                //     updatePoint(wallsRef.current[selectedWallId].endPointId, clickPoint);
                //     jointPoint = cloneDeep(clickPoint);
                //     jointWall = clickPoint?.wallId;
                //     flag = true;
                // }
                // if (!flag) {
                //     jointPoint = null;
                //     jointWall = null;
                // }
            }
        };

        const handleSelectMouseMove = (event) => {
            const { clientX, clientY } = event;
            handleSelectMove(clientX, clientY);
        };

        const handleSelectTouchMove = (event) => {
            const { clientX, clientY } = event.touches[0];
            handleSelectMove(clientX, clientY);
        }

        const handleSelectUp = (event) => {
            if (movedWall) {
                const copiedPoints = cloneDeep(points.current);
                const copiedWalls = cloneDeep(wallsRef.current);
                snapshotDispatch({
                    type: 'SNAPSHOT', payload: {
                        items: items,
                        walls: copiedWalls,
                        points: copiedPoints
                    }
                })
            }

            setMoving(false);
            if (jointPoint) {
                jointPoint.id = selectedPointId;
                addJoint(jointPoint, jointWall);
            }
            selectedPointId = null;
            selectedWallId = undefined;
        }
        if (topBarMode === 'Draw' && lineDrawing) {
            setCursorType("Crosshair")
            window.addEventListener('keydown', handleKeyDown)
            window.addEventListener('keyup', handleKeyUp)
            window.addEventListener('mousemove', handleLineMouseMove);
            window.addEventListener('touchmove', handleLineTouchMove);
            // Add event listener for mouse down for line drawing
            window.addEventListener('mousedown', handleLineMouseDown);
            window.addEventListener('touchstart', handleLineTouchStart);

            // Add event listener for mouse up for line drawing
            window.addEventListener('mouseup', handleLineMouseUp);
            // Cleanup: remove event listeners on component unmount
            return () => {
                window.removeEventListener('mousedown', handleLineMouseDown);
                window.removeEventListener('touchstart', handleLineTouchStart);

                window.removeEventListener('mouseup', handleLineMouseUp);
                window.removeEventListener('mousemove', handleLineMouseMove);
                window.removeEventListener('touchmove', handleLineTouchMove);
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('keyup', handleKeyUp);
                // isDrawingRef.current = false;
                setSelectedWall(undefined);
            };
        }
        if (topBarMode === 'Hand') {
            window.addEventListener('mousedown', handleSelectMouseDown);
            window.addEventListener('touchstart', handleSelectTouchStart);
            window.addEventListener('mousemove', handleSelectMouseMove);
            window.addEventListener('touchmove', handleSelectTouchMove);
            window.addEventListener('mouseup', handleSelectUp);
            window.addEventListener('touchend', handleSelectUp);

            window.addEventListener('keydown', handleKeyDown)
            window.addEventListener('keyup', handleKeyUp)
            return () => {
                window.removeEventListener('mousedown', handleSelectMouseDown);
                window.removeEventListener('touchstart', handleSelectTouchStart);
                window.removeEventListener('mousemove', handleSelectMouseMove);
                window.removeEventListener('touchmove', handleSelectTouchMove);
                window.removeEventListener('mouseup', handleSelectUp);
                window.removeEventListener('touchend', handleSelectUp);
            }

        }
        // eslint-disable-next-line
    }, [lineDrawing, topBarMode, scale, selecting, dragX, dragY, lockedWallsState, degree]);



    const handleVerticalChange = () => {
        if (selectedItem !== null) {
            let updatedItems = cloneDeep(items);
            updatedItems.forEach((item, index) => {
                if (selectedItem.id === item.id) {
                    item.scaleX = item.scaleX * -1 || -1;
                    item.x = shapeRefs.current[index].current.attrs.x;
                    item.y = shapeRefs.current[index].current.attrs.y;
                }
            });
            setItems(updatedItems);

        }
    }

    const handleHorizontalChange = () => {
        setChangeRotation(!changeRotation);
        if (selectedItem !== null && (!selectedItem.category?.includes('door') && !selectedItem.category?.includes('Window'))) {
            let updatedItems = cloneDeep(items);
            updatedItems.forEach((item, index) => {
                if (selectedItem.id === item.id) {
                    item.scaleY = item.scaleY * -1 || -1;
                    item.x = shapeRefs.current[index].current.attrs.x;
                    item.y = shapeRefs.current[index].current.attrs.y;
                }
            });
            setItems(updatedItems);
        }
    };

    const handleZIndexIncrease = () => {
        if (selectedItem !== null && (!selectedItem.category?.includes('door') && !selectedItem.category?.includes('Window'))) {
            let updatedItems = cloneDeep(items);
            let collidedItems = [];
            updatedItems.forEach((item) => {
                if (detectItemCollision(item, selectedItem) && item.id !== selectedItem.id) {
                    collidedItems.push(item);
                }
            });
            if (collidedItems.length > 0) {
                let nextZIndex = findNextZ(collidedItems, selectedItem);

                updatedItems.forEach((item) => {
                    if (item.id === selectedItem.id) {
                        item.zIndex = nextZIndex + 1;
                    }
                });
                setItems(updatedItems);

                const copiedPoints = cloneDeep(points.current);
                const copiedWalls = cloneDeep(wallsRef.current);

                snapshotDispatch({
                    type: 'SNAPSHOT', payload: {
                        items: updatedItems,
                        walls: copiedWalls,
                        points: copiedPoints
                    }
                })


            }
        }
    }

    const handleZIndexDecrease = () => {
        if (selectedItem !== null && (!selectedItem.category?.includes('door') && !selectedItem.category?.includes('Window'))) {
            let updatedItems = cloneDeep(items);
            let collidedItems = [];
            updatedItems.forEach((item, index) => {
                if (detectItemCollision(item, selectedItem) && item.id !== selectedItem.id) {
                    //item.zIndex = item.zIndex + 3 || 1;
                    collidedItems.push(item);
                }
            });
            if (collidedItems.length > 0) {
                let prevZIndex = findPreviousZ(collidedItems, selectedItem);

                updatedItems.forEach((item) => {
                    if (item.id === selectedItem.id) {
                        item.zIndex = prevZIndex - 1;
                    }
                });
                setItems(updatedItems);
                const copiedPoints = cloneDeep(points.current);
                const copiedWalls = cloneDeep(wallsRef.current);

                snapshotDispatch({
                    type: 'SNAPSHOT', payload: {
                        items: updatedItems,
                        walls: copiedWalls,
                        points: copiedPoints
                    }
                })

            }

        }
    }

    // Function to handle grouping
    const handleGroup = () => {
        // Create a new group with the selected items
        const newGroup = selectedItemIndexes.map(index => items[index].id);

        let isSub = isSubGroup(groupedItems, newGroup);
        if (isSub.val) {
            if (isSub.biggerGroup === newGroup.sort().join("")) {
                let groups = cloneDeep(groupedItems).filter((group) => group.sort().join("").toString() !== isSub.smallerGroup);
                setGroupedItems([...groups, newGroup]);
                setSelectedItemIndexes([newGroup]);
            }
        }
        else {
            setGroupedItems(prevGroupedItems => [...prevGroupedItems, newGroup]);
        }
    };

    // Function to handle ungrouping
    const handleUngroup = () => {
        setGroupedItems(prevGroupedItems => {
            const updatedGroups = prevGroupedItems.filter(group => {
                // Check if the group contains any of the selected indexes
                const shouldRemoveGroup = selectedItemIndexes.some(index => group.includes(items[index].id));
                return !shouldRemoveGroup;
            });

            return updatedGroups;
        });
    };

    // Function to Handle Hiding Items
    const handleHide = () => {
        if (hiddenItems.length > 0) {
            const reorderedItems = items.sort((a, b) => a.zIndex - b.zIndex).filter((item) => !hiddenItems.includes(item.id));
            const selected = selectedItemIndexes.map(index => reorderedItems[index].id);
            setHiddenItems((prev) => Array.from(new Set([...prev, ...selected])));
        }
        else {
            const selected = selectedItemIndexes.map(index => items[index].id);
            setHiddenItems((prev) => Array.from(new Set([...prev, ...selected])));
        }
    };
    // Function to Handle unhide
    const handleUnhide = () => {
        setHiddenItems([]);
    };
    /**
     * This UseEffect is Used for
     * Horizontal and Vertical Item Changes
     * and Door Rotations
     */
    useEffect(() => {
        const handleSelectKeyDown = (event) => {
            if ((event.key === 'V' || event.key === 'v') && !event.ctrlKey) {
                if(selectedItem && !isSelectedItemWindow(selectedItem)){
                    handleVerticalChange();
                }
                
            }

            else if ((event.key === 'H' || event.key === 'h') && !event.ctrlKey) {
                if(selectedItem && !isSelectedItemWindow(selectedItem)){
                    handleHorizontalChange();
                }
                
            }
        }

        const handleZIndexChange = (event) => {
            // Check if 'Z' or 'z' is pressed with '+' or '-'
            if (selectedItem && (event.ctrlKey)) {
                if (event.key === 'f' || event.key === 'F') {
                    // Increase zIndex
                    event.preventDefault(); // Prevent the default browser search behavior
                    handleZIndexIncrease();
                    setSelectedItem(null);
                    setSelectedItemIndex(-1);
                }
                else if (event.key === 'B' || event.key === 'b') {
                    // Decrease zIndex
                    event.preventDefault();
                    handleZIndexDecrease();
                    setSelectedItem(null);
                    setSelectedItemIndex(-1);
                }

            }
        };

        window.addEventListener('keydown', handleSelectKeyDown);
        window.addEventListener('keydown', handleZIndexChange);

        if (topBarMode === 'Hand') {
            return () => {
                window.removeEventListener('keydown', handleSelectKeyDown);
                window.removeEventListener('keydown', handleZIndexChange);
            }
        }
        // eslint-disable-next-line
    }, [topBarMode, changeRotation, selectedItem, items]);

    useEffect(() => {
        fetchProject();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchScreenshot();
        // eslint-disable-next-line
    }, [ssTrigger])

    useEffect(() => {
        const handleMouseUpPos = (event) => {
            // Check if the left mouse button was released
            if (event.button === 0) {
                updateClickedPos(event);
            }
        };

        const handleDragEndPos = (event) => {
            updateClickedPos(event);
        };

        const updateClickedPos = (event) => {
            let { clientX, clientY } = event;

            if (event.type === 'touchstart' || event.type === 'touchmove' || event.type === 'touchend') {
                // Handle touch events
                const touch = event.touches[0] || event.changedTouches[0]; // First touch point
                clientX = touch.clientX;
                clientY = touch.clientY;
            } else {
                // Handle mouse events
                clientX = event.clientX;
                clientY = event.clientY;
            }
            //let x = (clientX + offsetX - dragX) / scale;
            //let y = (-1 * (clientY + offsetY - dragY) / scale);
            setClickedPos({ x: clientX, y: clientY });
        };

        window.addEventListener('mouseup', handleMouseUpPos);
        window.addEventListener('dragend', handleDragEndPos);

        return () => {
            window.removeEventListener('mouseup', handleMouseUpPos);
            window.removeEventListener('dragend', handleDragEndPos);
        };
    }, [dragX, dragY, scale, offsetX, offsetY]);

    /**
     * This UseEffect is used for loading initial
     * wall shape to user
     */
    useEffect(() => {
        let data;
        if (roomShape === "R") {
            data = cloneDeep(InitialRShaped.walls);
            setWalls(InitialRShaped.walls);
            wallsRef.current = InitialRShaped.walls;
            points.current = InitialRShaped.points;
        }
        else if (roomShape === 'Z') {
            data = cloneDeep(InitialZShaped.walls);
            setWalls(InitialZShaped.walls);
            wallsRef.current = InitialZShaped.walls;
            points.current = InitialZShaped.points;
        }
        else if (roomShape === 'T') {
            data = cloneDeep(InitialTShaped.walls);
            setWalls(InitialTShaped.walls);
            wallsRef.current = InitialTShaped.walls;
            points.current = InitialTShaped.points;
        } else if (roomShape === "L") {
            data = cloneDeep(InitialLShaped.walls);
            setWalls(InitialLShaped.walls);
            wallsRef.current = InitialLShaped.walls;
            points.current = InitialLShaped.points;
        }
        const copiedPoints = cloneDeep(points.current);
        const copiedWalls = cloneDeep(data);
        if (roomShape !== '' || roomShape !== 'custom') {
            snapshotDispatch({
                type: 'SNAPSHOT', payload: {
                    items: items,
                    walls: copiedWalls,
                    points: copiedPoints
                }
            })
        }
        setSelecting(true);
        // eslint-disable-next-line
    }, [roomShape]);

    useEffect(() => {
        if (onPanel) {
            if (topBarMode === "Draw") {
                setLineDrawing(false);
            }
            else if (topBarMode === 'Hand') {
                setSelecting(false);
            }
        }
        else {
            if (topBarMode === "Draw") {
                setLineDrawing(true);
            }
            else if (topBarMode === 'Hand') {
                setSelecting(true);
            }
        }
        // eslint-disable-next-line
    }, [onPanel]);


    useEffect(() => {
        if (changeRotation) {
            setChangeRotation(0);
            if (!selectedItem)
                return;
            let updItems = cloneDeep(items);
            let ind = updItems.findIndex(item => item?.id === selectedItem?.id)
            if (updItems[ind].direction === 0)
                updItems[ind].direction = 1;
            else
                updItems[ind].direction = 0;
            setItems(updItems);
        }
        // eslint-disable-next-line
    }, [changeRotation, items]);

    /**
     * This Useeffect 
     * sets up the language
     */
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('languageCode'));
    }, []);

    const panelMode = useMemo(() => {
        if (topBarMode === "Hand" && !selectedItem && selectedWall === undefined && !selectedFloor) {
            return "Room";
        }
        else if (topBarMode === "Hand" && (selectedWall !== undefined && !selectedItem) && !selectedFloor) {
            return "Select";
        }
        else if (selectedFloor && selectedWall === undefined && !selectedItem) {
            return "Floor";
        }
        else if (topBarMode === "Draw" && !selectedItem && !selectedFloor) {
            return "Draw";
        }
        else if (selectedItem && selectedItemIndex !== -1) {
            return "Specific";
        }
        else {
            return 'Room';
        }
        // eslint-disable-next-line
    }, [topBarMode, selectedItem, selectedWall, selectedFloor]);

    // useEffect(() => {
    //     let updatedItems = cloneDeep(items);
    //     updatedItems.forEach((item, index) => {
    //         if (!(item?.panelIcon?.includes("Window") || item?.panelIcon?.includes("Door") || item?.panelIcon?.includes("door")) || item?.panelIcon?.includes("Shower")) {
    //             return;
    //         }
    //         let height = item.height;
    //         let width = item.width;


    //         if (item?.panelIcon?.includes("Window") && item?.lockedWallId && getWall(item.lockedWallId)) {
    //             item.width = getWall(item.lockedWallId).thickness;
    //             height = getWall(item.lockedWallId).thickness / 2;
    //         }
    //         const itemCoordinats = calculateRightCorner(item.x, item.y, width, height, item.rotation);
    //         let closestDistance = Infinity;
    //         let closestWall = null;

    //         walls.forEach(wall => {
    //             if (!wall?.endPointId || !wall?.startPointId)
    //                 return;
    //             const wallStart = getPoint(wall.startPointId);
    //             const wallEnd = getPoint(wall.endPointId);

    //             const distanceToWall = distancePointToLine(itemCoordinats, wallStart, wallEnd);

    //             if (distanceToWall < closestDistance && distanceToWall) {
    //                 closestDistance = distanceToWall;
    //                 closestWall = wall;
    //             }
    //             if (distanceToWall <= 15 && wall.id === item.lockedWallId) {
    //                 closestDistance = -1;
    //                 closestWall = wall;
    //             }

    //         });
    //         if ((closestWall && closestDistance <= item.height) || (item.lockedWallId && closestWall)) {
    //             if (item?.panelIcon?.includes("Window")) {
    //                 item.width = closestWall.thickness;
    //                 height = closestWall.thickness / 2;
    //             }
    //             const wallStart = getPoint(closestWall.startPointId);
    //             const wallEnd = getPoint(closestWall.endPointId);
    //             const closestPoint = closestPointOnLine(itemCoordinats, wallStart, wallEnd);
    //             let newX = closestPoint.x;
    //             let newY = closestPoint.y;
    //             const dx = wallEnd.x - wallStart.x;
    //             const dy = wallEnd.y - wallStart.y;
    //             let rotation = Math.atan2(dy, dx) * (180 / Math.PI);
    //             if (rotation < 0)
    //                 rotation += 360;
    //             if (item.rotation < 0)
    //                 item.rotation += 360;

    //             const tempRot = rotation;
    //             if (item.direction > 0) {
    //                 rotation += 180;
    //             }
    //             if (rotation > 360)
    //                 rotation -= 360;
    //             item.rotation = rotation - 270;
    //             let corner = calculateOppositeCorner(newX, newY, item.height, item.width - closestWall.thickness / 2, rotation);
    //             if (item.direction == 1) {
    //                 corner = calculateRightCorner(corner.x, corner.y, item.height, item.width - closestWall.thickness / 2, rotation);
    //                 item.direction = item.direction + 1;
    //             }
    //             if (item.direction == -1) {
    //                 corner = calculateRightCorner(corner.x, corner.y, item.height, item.width - closestWall.thickness / 2, rotation);
    //                 item.direction = item.direction - 1;
    //             }
    //             item.x = corner.x;
    //             item.y = corner.y;
    //             item.lockedWallId = closestWall.id;
    //         }

    //     });
    //     let change = false;
    //     updatedItems.forEach((item, index) => {
    //         if (updatedItems[index].x != items[index].x || updatedItems[index].y != items[index].y || updatedItems[index].rotation != items[index].rotation)
    //             change = true;
    //     });
    //     if (change) {
    //         setItems(updatedItems);
    //     }
    // }, [items]);

    // useEffect(() => {
    //     let updatedItems = cloneDeep(items);
    //     updatedItems.forEach((item, index) => {
    //         if (!(item?.panelIcon?.includes("Window") || item?.panelIcon?.includes("Door") || item?.panelIcon?.includes("door")) || item?.panelIcon?.includes("Shower")) {
    //             return;
    //         }


    //         let height = item.height;
    //         let width = item.width;


    //         if (item?.panelIcon?.includes("Window") && item?.lockedWallId && getWall(item.lockedWallId)) {
    //             item.width = getWall(item.lockedWallId).thickness;
    //             height = getWall(item.lockedWallId).thickness / 2;
    //         }
    //         const itemCoordinats = calculateRightCorner(item.x, item.y, width, height, item.rotation);
    //         let closestDistance = Infinity;
    //         let closestWall = null;

    //         walls.forEach(wall => {
    //             if (item?.lockedWallId && item?.lockedWallId != wall?.id)
    //                 return;
    //             if (!wall?.endPointId || !wall?.startPointId)
    //                 return;

    //             const wallStart = getPoint(wall.startPointId);
    //             const wallEnd = getPoint(wall.endPointId);

    //             const distanceToWall = distancePointToLine(itemCoordinats, wallStart, wallEnd);

    //             if (distanceToWall < closestDistance && distanceToWall) {
    //                 closestDistance = distanceToWall;
    //                 closestWall = wall;
    //             }
    //             if (distanceToWall <= 15 && wall.id === item.lockedWallId) {
    //                 closestDistance = -1;
    //                 closestWall = wall;
    //             }
    //         });
    //         if ((closestWall && closestDistance <= item.height) || (item.lockedWallId && closestWall)) {
    //             if (item?.panelIcon?.includes("Window")) {
    //                 item.width = closestWall.thickness;
    //                 height = closestWall.thickness / 2;
    //             }
    //             const wallStart = getPoint(closestWall.startPointId);
    //             const wallEnd = getPoint(closestWall.endPointId);
    //             const closestPoint = closestPointOnLine(itemCoordinats, wallStart, wallEnd);
    //             let newX = closestPoint.x;
    //             let newY = closestPoint.y;
    //             const dx = wallEnd.x - wallStart.x;
    //             const dy = wallEnd.y - wallStart.y;
    //             let rotation = Math.atan2(dy, dx) * (180 / Math.PI);
    //             if (rotation < 0)
    //                 rotation += 360;
    //             if (item.rotation < 0)
    //                 item.rotation += 360;

    //             const tempRot = rotation;
    //             if (item.direction > 0) {
    //                 rotation += 180;
    //             }
    //             if (rotation > 360)
    //                 rotation -= 360;
    //             item.rotation = rotation - 270;
    //             let corner = calculateOppositeCorner(newX, newY, item.height, item.width - closestWall.thickness / 2, rotation);

    //             if (item.direction == 1) {
    //                 corner = calculateRightCorner(corner.x, corner.y, item.height, item.width - closestWall.thickness / 2, rotation);
    //                 item.direction = item.direction + 1;
    //             }
    //             if (item.direction == -1) {
    //                 corner = calculateRightCorner(corner.x, corner.y, item.height, item.width - closestWall.thickness / 2, rotation);
    //                 item.direction = item.direction - 1;
    //             }
    //             item.x = corner.x;
    //             item.y = corner.y;
    //             item.lockedWallId = closestWall.id;
    //         }

    //     });
    //     let change = false;
    //     updatedItems.forEach((item, index) => {
    //         if (updatedItems[index].x != items[index].x || updatedItems[index].y != items[index].y || updatedItems[index].rotation != items[index].rotation)
    //             change = true;
    //     });
    //     if (change) {
    //         setItems(updatedItems);
    //     }
    // }, [wallsRef.current]);

    return (
        <>
            <div className={styles.plan_view} id='whiteboard-content' onContextMenu={(e) => {
                e.preventDefault();
                handleRightClick(e);
            }} onMouseDownCapture={(e) => {
                if (contextMenuVisible === true) {
                    e.preventDefault();
                    setContextMenuVisible(false);
                }

            }}>
                <Stage
                    width={width}
                    height={height}
                    offsetX={offsetX / scale}
                    offsetY={offsetY / -scale}
                    scaleX={scale} scaleY={-scale}
                    onWheel={handleWheel}
                    draggable={topBarMode === 'Hand' && cursorType !== 'pointer'}
                    onDragMove={handleDrag}
                    style={{
                        cursor: cursorType
                    }}
                    fill={"red"}

                >

                    <PlanLayer />

                    <RoomLayer
                        walls={
                            wallsRef?.current?.map(wall => {
                                if (!wall || !points?.current) {
                                    return null;
                                }
                                const startPointIndex = points.current.findIndex(point => point?.id === wall?.startPointId);
                                const startPoint = startPointIndex === -1 ? null : points.current[startPointIndex];
                                const endPointIndex = points.current.findIndex(point => point?.id === wall?.endPointId);
                                const endPoint = endPointIndex === -1 ? null : points.current[endPointIndex];
                                if (startPoint && startPoint.x !== undefined && startPoint.y !== undefined &&
                                    endPoint && endPoint.x !== undefined && endPoint.y !== undefined && (startPoint !== endPoint)) {
                                    const length = distance(startPoint, endPoint);
                                    return {
                                        points: {
                                            start: startPoint,
                                            end: endPoint
                                        },
                                        startPointId: wall.startPointId,
                                        endPointId: wall.endPointId,
                                        thickness: wall.thickness,
                                        color: wall.color,
                                        length: length,
                                        id: wall.id,
                                        status: wall.status
                                    };
                                }
                                return null;
                            })?.filter(Boolean)}
                        points={points.current}
                        setCursorType={setCursorType}
                        stroke={lineColor}
                        thickness={lineThickness}
                        projectId={id}
                        shapeRefs={shapeRefs}
                        items={items}
                        history={history}
                        setItems={setItems}
                        scale={transform.scale}
                        degree={transform.degree}
                        topBarMode={topBarMode}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        setSelectedItemIndex={setSelectedItemIndex}
                        deleteIndex={deleteIndex}
                        clickedPos={clickedPos}
                        onPanel={onPanel}
                        selectedWall={wallsRef?.current[selectedWall]}
                        setSelectedWall={setSelectedWall}
                        showMeasurements={showMeasurements}
                        isDrawing={isDrawingRef.current}
                        movingWall={moving}
                        cursorPosition={cursorPosition}
                        wallsRef={wallsRef ?? []}
                        selectedFloor={selectedFloor}
                        setSelectedFloor={setSelectedFloor}
                        index={index}
                        floors={floors}
                        setFloors={setFloors}
                        isDrawingState={isDrawing}
                        offsetX={offsetX}
                        offsetY={offsetY}
                        dragX={dragX}
                        dragY={dragY}
                        selectedItemIndexes={selectedItemIndexes}
                        setSelectedItemIndexes={setSelectedItemIndexes}
                        groupedItems={groupedItems}
                        hiddenItems={hiddenItems}
                        clickedItem={clickedItem}
                        setClickedItem={setClickedItem}
                        handleItemRotate={handleItemRotate}
                        contextMenuVisible={contextMenuVisible}
                        lockedWallsState={lockedWallsState}
                        rightClicked={rightClicked}
                        setRightClicked={setRightClicked}
                    />
                </Stage>


            </div>

            <div className={styles.ui} onMouseDownCapture={(e) => {
                if (contextMenuVisible === true) {
                    e.preventDefault();
                    setContextMenuVisible(false);
                }

            }}>
                <div className={styles.layout}>
                    {<div className={styles.test}
                        onMouseEnter={() => setOnPanel(true)}
                        onMouseLeave={() => setOnPanel(false)}
                        onTouchStart={() => setOnPanel(true)}
                        onTouchEnd={() => setOnPanel(false)}
                    >
                        <Flex gap={0.5}>
                            <Panel>
                                <ItemMenu
                                    projectId={id}
                                    projectName={projectName}
                                    items={menuItems}
                                    path={path}
                                    setPath={setPath}
                                    handleItemClick={handleItemClick}
                                    handleItemDrag={handleItemDrag}
                                    leftPanelOpen={leftPanelOpen}
                                />
                            </Panel>
                            {/*hoverItem && (
                                <Panel style={{
                                    height: '12.5em'
                                }}>
                                    <Section>
                                        <span style={{ height: '10em', marginBottom: '0.1em' }}>{hoverItem.icon}</span>
                                        <span>{hoverItem.title}</span>
                                    </Section>

                                </Panel>
                            )*/}
                            <Panel
                                style={{
                                    alignSelf: 'center',
                                    alignContent: 'center',
                                    height: '70px',
                                    width: '6px',
                                    borderRadius: '0 25px 25px 0'
                                }}
                                onClick={() => setLeftPanelOpen(!leftPanelOpen)}
                            >
                                {leftPanelOpen && <CollapseIcon />}
                                {!leftPanelOpen && <ExpandIcon />}
                            </Panel>
                        </Flex>
                    </div>}
                    {<div className={styles.topbar}>
                        <Flex className={styles.test}
                            onMouseEnter={() => setOnPanel(true)}
                            onMouseLeave={() => setOnPanel(false)}
                            onTouchStart={() => setOnPanel(true)}
                            onTouchEnd={() => setOnPanel(false)}>
                            <Topbar
                                mode={topBarMode}
                                setMode={setTopBarMode}
                                //lineHist={lineHist}
                                scale={scale}
                                transform={transform}
                                setZoom={setZoom}
                                points={points}
                                deleteItems={handleObjectDeletion}
                                selectedItem={selectedItem}
                                selectedItemIndexes={selectedItemIndexes}
                                copyItems={copyItems}
                                undo={undo}
                                redo={redo}
                                cut={cut}
                                copy={copy}
                                paste={paste}
                                captureScreenshot={captureScreenshot}
                                setShowMeasurements={setShowMeasurements}
                                selectedWall={selectedWall}
                                setDeleteState={setDeleteState}
                                lockedWallsState={lockedWallsState}
                                setLockedWallsState={setLockedWallsState}
                                items={items}
                                groupedItems={groupedItems}
                                showMeasurements={showMeasurements}
                                ActionMenuActions={{
                                    group: handleGroup,
                                    ungroup: handleUngroup,
                                    vFlip: handleVerticalChange,
                                    hFlip: handleHorizontalChange,
                                    rotate: handleItemRotate,
                                    zUp: handleZIndexIncrease,
                                    zDown: handleZIndexDecrease,
                                    shortcut: () => setShortcutVisible(true),
                                    shortcutOpenClose : () => setShortcutVisible(!shortCutVisible)
                                }}
                            />
                        </Flex>

                        {shortCutVisible && <Panel className={styles.test}>
                            <ShortcutPanel handleVisible={() => setShortcutVisible(false)}/>
                        </Panel>}

                    </div>}
                    {<div className={styles.test} onMouseEnter={() => setOnPanel(true)}
                        onMouseLeave={() => setOnPanel(false)}
                        onTouchStart={() => setOnPanel(true)}
                        onTouchEnd={() => setOnPanel(false)}>
                        <Panel
                            style={{
                                alignSelf: 'center',
                                alignContent: 'center',
                                height: '70px',
                                width: '6px',
                                borderRadius: '25px 0 0 25px'
                            }}
                            onClick={() => setRightPanelOpen(!rightPanelOpen)}
                        >
                            {rightPanelOpen && <ExpandIcon />}
                            {!rightPanelOpen && <CollapseIcon />}
                        </Panel>
                        {panelMode === 'Room' &&
                            <RoomPanel
                                thickness={lineThickness}
                                rightPanelOpen={rightPanelOpen}
                                screenshots={screenshots}
                                setScreenshots={setScreenshots}
                            />
                        }
                        {panelMode === 'Select' &&
                            <Selectpanel
                                setStroke={setLineColor}
                                stroke={lineColor}
                                lineThickness={lineThickness}
                                setLineThickness={setLineThickness}
                                setLineLength={setLineLength}
                                lineLength={lineLength}
                                deleteState={deleteState}
                                setDeleteState={setDeleteState}
                                rightPanelOpen={rightPanelOpen}
                                screenshots={screenshots}
                                setScreenshots={setScreenshots}
                            />}
                        {panelMode === 'Floor' &&
                            <FloorPanel
                                selectedFloor={selectedFloor}
                                rightPanelOpen={rightPanelOpen}
                                floors={floors}
                                setFloors={setFloors}
                            />}
                        {panelMode === 'Specific' && <Specificpanel
                            item={selectedItem}
                            items={items}
                            setItems={setItems}
                            setSpecificItem={setSelectedItem}
                            index={selectedItemIndex}
                            setIndex={setSelectedItemIndex}
                            rightPanelOpen={rightPanelOpen}
                            screenshots={screenshots}
                            setScreenshots={setScreenshots}
                        />}
                        {panelMode === 'Draw' &&
                            <Drawpanel
                                setStroke={setLineColor}
                                stroke={lineColor}
                                lineThickness={lineThickness}
                                setLineThickness={setLineThickness}
                                rightPanelOpen={rightPanelOpen}
                                screenshots={screenshots}
                                setScreenshots={setScreenshots}
                            />}

                    </div>}

                </div>
            </div>

            <div className={styles.ui} >

                {(showDialog) && <div className={styles.test} onMouseEnter={() => setOnPanel(true)} onMouseLeave={() => setOnPanel(false)}>
                    {helperOpen && <HelperModal helperOpen={helperOpen} onClose={() => setHelperOpen(false)} />}
                    {showDialog && roomType === "" && <RoomTypeDialog select={setRoomType} projectId={id} />}
                    {showDialog && (roomType !== "" && roomShape === "") && <RoomShapeDialog select={setRoomShape} />}

                </div>}

            </div>

            {<RightClickMenu
                visible={contextMenuVisible}
                position={clickedPos}
                onClose={() => setContextMenuVisible(false)}
                selectedItemIndexes={selectedItemIndexes}
                handleDelete={handleObjectDeletion}
                selectedItem={selectedItem}
                items={items}
                cut={cut}
                copy={copy}
                paste={() => {
                    paste();
                    setCopyItems([]);
                }}
                copyItems={copyItems}
                groupedItems={groupedItems}
                hiddenItems={hiddenItems}
                transform={transform}
                handleHorizontalChange={handleHorizontalChange}
                handleVerticalChange={handleVerticalChange}
                zIndexIncrase={handleZIndexIncrease}
                zIndexDecrease={handleZIndexDecrease}
                handleGroup={handleGroup}
                handleUngroup={handleUngroup}
                handeHide={handleHide}
                handleUnhide={handleUnhide}
                handleRotate90={(e) => {
                    const pointerX = e.clientX;
                    const pointerY = e.clientY;
                    console.log(transform.degree);
                    setZoom((scale) => scale, (degree) => degree < 270 ? degree + 90 : 0, pointerX, pointerY);
                }}
                handleItemRotate={handleItemRotate}
            />}
        </>
    )
}