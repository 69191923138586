import { Image } from 'antd';
import bathroomCabinet1Board from '../../../assets/Bathroom/BathroomItems2/bathroom-cabinet_01_A.png';
import bathroomCabinet1Panel from '../../../assets/Bathroom/BathroomItems2/bathroom-cabinet_01_B.jpg';

import bathroomCabinet2Board from '../../../assets/Bathroom/BathroomItems2/bathroom-cabinet_02_A.png';
import bathroomCabinet2Panel from '../../../assets/Bathroom/BathroomItems2/bathroom-cabinet_02_B.png';

// Lavatory Sinks
import lavatorySink1Board from '../../../assets/Bathroom/BathroomItems2/Lavatory-Sink_01_A.png';
import lavatorySink1Panel from '../../../assets/Bathroom/BathroomItems2/Lavatory-Sink_01_B.jpg';

import lavatorySink2Board from '../../../assets/Bathroom/BathroomItems2/Lavatory-Sink_02_A.png';
import lavatorySink2Panel from '../../../assets/Bathroom/BathroomItems2/Lavatory-Sink_02_B.jpg';

import lavatorySink3Board from '../../../assets/Bathroom/BathroomItems2/Lavatory-Sink_03_A.png';
import lavatorySink3Panel from '../../../assets/Bathroom/BathroomItems2/Lavatory-Sink_03_B.jpg';

import lavatorySink4Board from '../../../assets/Bathroom/BathroomItems2/Lavatory-Sink_04_A.png';
import lavatorySink4Panel from '../../../assets/Bathroom/BathroomItems2/Lavatory-Sink_04_B.jpg';

import plants1Board from '../../../assets/Bathroom/BathroomItems2/plants_01_A.png';
import plants1Panel from '../../../assets/Bathroom/BathroomItems2/plants_01_B.jpg';

// Shower Curtains
import showerCurtain1Board from '../../../assets/Bathroom/BathroomItems2/Shower-Curtain_01_A.png';
import showerCurtain1Panel from '../../../assets/Bathroom/BathroomItems2/Shower-Curtain_01_BB.jpg';

// Shower Rooms
import showerRoom1Board from '../../../assets/Bathroom/BathroomItems2/Shower-Room_01.png';
import showerRoom1Panel from '../../../assets/Bathroom/BathroomItems2/Shower-Room_01_B.png';

import showerRoom2Board from '../../../assets/Bathroom/BathroomItems2/Shower-Room_02_A.png';
import showerRoom2Panel from '../../../assets/Bathroom/BathroomItems2/Shower-Room_02_B.png';

import showerRoom3Board from '../../../assets/Bathroom/BathroomItems2/Shower-Room_03_A.png';
import showerRoom3Panel from '../../../assets/Bathroom/BathroomItems2/Shower-Room_03_B.png';

import showerRoom4Board from '../../../assets/Bathroom/BathroomItems2/Shower-Room_04_A.png';
import showerRoom4Panel from '../../../assets/Bathroom/BathroomItems2/Shower-Room_04_B.png';

import showerRoom5Board from '../../../assets/Bathroom/BathroomItems2/Shower-Room_05_A.png';
import showerRoom5Panel from '../../../assets/Bathroom/BathroomItems2/Shower-Room_05_B.png';

import showerRoom6Board from '../../../assets/Bathroom/BathroomItems2/Shower-Room_06_A.png';
import showerRoom6Panel from '../../../assets/Bathroom/BathroomItems2/Shower-Room_06_B.png';

import showerRoom7Board from '../../../assets/Bathroom/BathroomItems2/Shower-Room_07_A.png';
import showerRoom7Panel from '../../../assets/Bathroom/BathroomItems2/Shower-Room_07_B.png';

import showerRoom8Board from '../../../assets/Bathroom/BathroomItems2/Shower-Room_08_A.png';
import showerRoom8Panel from '../../../assets/Bathroom/BathroomItems2/Shower-Room_08_B.png';


export const ItemIcon = ({ src }) => <Image src={src} height='100%' />;

export const bathroomItems1 = [
    {
        "key": "bathroomCabinet1",
        "title": "Sink 7",
        "type": "item",
        "description": "2.49m x 0.64m x 1.17m (DWH)",
        "icon": <ItemIcon src={bathroomCabinet1Panel} />,
        "panelIcon": bathroomCabinet1Panel,
        "boardIcon": bathroomCabinet1Board,
        "height": 25,
        "width": 50,
        "zIndex":5
    },
    {
        "key": "bathroomCabinet2",
        "title": "Sink 8",
        "type": "item",
        "description": "1.52m x 0.93m x 2.02m (DWH)",
        "icon": <ItemIcon src={bathroomCabinet2Panel} />,
        "panelIcon": bathroomCabinet2Panel,
        "boardIcon": bathroomCabinet2Board,
        "height": 30,
        "width": 50,
        "zIndex":5
    },
    {
        "key": "lavatorySink1",
        "title": "Bathroom Cabinet 1",
        "type": "item",
        "description": "0.86m x 0.49m x 1.4m (DWH)",
        "icon": <ItemIcon src={lavatorySink1Panel} />,
        "panelIcon": lavatorySink1Panel,
        "boardIcon": lavatorySink1Board,
        "height": 26,
        "width": 40,
        "zIndex":5
    },
    {
        "key": "lavatorySink2",
        "title": "Bathroom Cabinet 2",
        "type": "item",
        "description": "0.7m x 0.55m x 1.56m (DWH)",
        "icon": <ItemIcon src={lavatorySink2Panel} />,
        "panelIcon": lavatorySink2Panel,
        "boardIcon": lavatorySink2Board,
        "height": 26,
        "width": 40,
        "zIndex":5
    },
    {
        "key": "lavatorySink3",
        "title": "Bathroom Cabinet 3",
        "type": "item",
        "description": "0.94m x 0.49m x 1.45m (DWH)",
        "icon": <ItemIcon src={lavatorySink3Panel} />,
        "panelIcon": lavatorySink3Panel,
        "boardIcon": lavatorySink3Board,
        "height": 30,
        "width": 45,
        "zIndex":5
    },
    {
        "key": "lavatorySink4",
        "title": "Bathroom Cabinet 4",
        "type": "item",
        "description": "1.48m x 0.62m x 2.11m (DWH)",
        "icon": <ItemIcon src={lavatorySink4Panel} />,
        "panelIcon": lavatorySink4Panel,
        "boardIcon": lavatorySink4Board,
        "height": 26,
        "width": 40,
        "zIndex":5
    },
    {
        "key": "plant1",
        "title": "Plant 1",
        "type": "item",
        "description": "0.81m x 0.13m x 0.71m (DWH)",
        "icon": <ItemIcon src={plants1Panel} />,
        "panelIcon": plants1Panel,
        "boardIcon": plants1Board,
        "height": 10,
        "width": 10,
        "zIndex":5
    },
    {
        "key": "showerCurtain1",
        "title": "Shower Curtain",
        "type": "item",
        "description": "0.81m x 0.13m x 0.71m (DWH)",
        "icon": <ItemIcon src={showerCurtain1Panel} />,
        "panelIcon": showerCurtain1Panel,
        "boardIcon": showerCurtain1Board,
        "height": 30,
        "width": 30,
        "zIndex":5
    },
    {
        "key": "showerRoom1",
        "title": "Shower Room 1",
        "type": "item",
        "description": "1.19m x 0.91m x 2.01m (DWH)",
        "icon": <ItemIcon src={showerRoom1Panel} />,
        "panelIcon": showerRoom1Panel,
        "boardIcon": showerRoom1Board,
        "height": 45,
        "width": 55,
        "zIndex": 2
    },
    {
        "key": "showerRoom2",
        "title": "Shower Room 2",
        "type": "item",
        "description": "0.99m x 0.99m x 1.91m (DWH)",
        "icon": <ItemIcon src={showerRoom2Panel} />,
        "panelIcon": showerRoom2Panel,
        "boardIcon": showerRoom2Board,
        "height": 40,
        "width": 55,
        "zIndex": 2
    },
    {
        "key": "showerRoom3",
        "title": "Shower Room 3",
        "type": "item",
        "description": "1.75m x 1.12m x 2.54m (DWH)",
        "icon": <ItemIcon src={showerRoom3Panel} />,
        "panelIcon": showerRoom3Panel,
        "boardIcon": showerRoom3Board,
        "height": 40,
        "width": 55,
        "zIndex": 2
    },
    {
        "key": "showerRoom4",
        "title": "Shower Room 4",
        "type": "item",
        "description": "1.20m x 0.81m x 2.09m (DWH)",
        "icon": <ItemIcon src={showerRoom4Panel} />,
        "panelIcon": showerRoom4Panel,
        "boardIcon": showerRoom4Board,
        "height": 40,
        "width": 55,
        "zIndex": 2
    },
    {
        "key": "showerRoom5",
        "title": "Shower Room 5",
        "type": "item",
        "description": "1.81m x 0.97m x 2.12m (DWH)",
        "icon": <ItemIcon src={showerRoom5Panel} />,
        "panelIcon": showerRoom5Panel,
        "boardIcon": showerRoom5Board,
        "height": 40,
        "width": 55,
        "zIndex": 2
    },
    {
        "key": "showerRoom6",
        "title": "Shower Room 6",
        "type": "item",
        "description": "1m x 1m x 1.95m (DWH)",
        "icon": <ItemIcon src={showerRoom6Panel} />,
        "panelIcon": showerRoom6Panel,
        "boardIcon": showerRoom6Board,
        "height": 40,
        "width": 55,
        "zIndex": 2
    },
    {
        "key": "showerRoom7",
        "title": "Shower Room 7",
        "type": "item",
        "description": "0.91m x 0.91m x 2.06m (DWH)",
        "icon": <ItemIcon src={showerRoom7Panel} />,
        "panelIcon": showerRoom7Panel,
        "boardIcon": showerRoom7Board,
        "height": 60,
        "width": 80,
        "zIndex": 2
    },
    {
        "key": "showerRoom8",
        "title": "Shower Room 8",
        "type": "item",
        "description": "1.02m x 1.15m x 2.09m (DWH)",
        "icon": <ItemIcon src={showerRoom8Panel} />,
        "panelIcon": showerRoom8Panel,
        "boardIcon": showerRoom8Board,
        "height": 60,
        "width": 80,
        "zIndex": 2
    }    
];