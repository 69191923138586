import React, { useCallback, useEffect, useState } from 'react'
import styles from './dashboard.module.css';

import { HomeIcon, ArchiveIcon, TutorialIcon } from '../../components/common/icons/icons';
import HStack from '../../components/stack/hStack';
import VStack from '../../components/stack/vStack';
import { Flex, Divider, Button, Tooltip } from 'antd';
import { SettingsIcon } from '../../components/common/icons/icons';
import Section from '../../components/common/section/section';
import Menu from '../../components/common/menu';
import ActionPanel from '../../components/panels/actionPanel';
import CardPanel from '../../components/panels/cardPanel';
import SettingsForm from '../../components/forms/settingsForm/settingsForm';
import { useAuthContext } from '../../hooks/contextHooks/useAuthContext';
import axios from 'axios';
import { useProjectContext } from '../../hooks/contextHooks/useProjectContext';
import config from '../../config/config.json';


import VideoPanel from '../../components/panels/videoPanel';
import ProfileAvatar from '../../components/common/avatars/profileAvatar';
import { useUserAPI } from '../../hooks/apiHooks/userHooks/useUserAPI';
import { updateSession } from '../../functions/fetchers/fetchEntryData';
import { useTranslation } from 'react-i18next';
import { useLngContext } from '../../hooks/contextHooks/useLngContext';
import i18n from '../../i18n';
import { CreditCardOutlined } from '@ant-design/icons';
import PaymentMenu from '../../components/paymentMenu';



export default function Dashboard() {


    // Hooks
    const { user, dispatch } = useAuthContext();
    const { languageCode } = useLngContext();
    const { trigger } = useProjectContext();
    const [selectedSection, setSelectedSection] = useState(0);
    const [projects, setProjects] = useState([]);
    const [screenshots, setScreenshots] = useState([]);
    const { t } = useTranslation();
    const src = "";
    const { getUserPhoto, userDeleted } = useUserAPI();

    let items = [
        { name: t('myprojects'), icon: (<HomeIcon />) },
        { name: t('tutorials'), icon: (<TutorialIcon />) },
        { name: t('archive'), icon: (<ArchiveIcon />) },
        //{name: t('Payments'), icon: (<CreditCardOutlined />)}
    ];

    const videos = [
        { id: 1, title: t('keyfeaturesOverview'), video: "https://www.youtube.com/embed/vL1coZwC7Tk?si=ayuD9y_uWnTKap87" },
        { id: 2, title: t('creatingANewProject'), video: "https://www.youtube.com/embed/b-aRgQHyaNU?si=IZKiqQMqt72buDNJ" },
        { id: 3, title: t("beginnersGuideToDrawARoom"), video: "https://www.youtube.com/embed/W6CA-xSGSUQ?si=cU6iKA7DYFnTe4JU" },
        { id: 4, title: t("decoratingYourHouse"), video: "https://www.youtube.com/embed/s45U-Z78faE?si=tYDgXTBY7kndh5J3" }
    ]
    // Callback
    const fetchProjects = useCallback(async () => {
        if (localStorage.getItem('user')) {
            const res = await axios.get(config.REACT_APP_API_MAIN_ROUTE + "/projects/all/" + user.userIdMongo, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
            const resData = res.data
            setProjects(resData.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger, user.token, user.userIdMongo]);

    const fetchScreenshot = useCallback(async () => {
        if (localStorage.getItem('user')) {
            const res = await axios.get(config.REACT_APP_API_MAIN_ROUTE + "/screenshots/all/" + user.userIdMongo, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
            let array = res.data.data;
            let updatedArray = array.map((item) => {
                let data = item.img.data.data;
                const base64String = btoa(
                    new Uint8Array(data)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                const mimeType = res.headers['content-type']; // Make sure your backend sets this header
                let newVal = `data:${mimeType};base64,${base64String}`;
                return { ...item, src: newVal };
            })
            setScreenshots(updatedArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const fetchProfilePic = useCallback(async () => {
        if (localStorage.getItem('user')) {
            const res = await getUserPhoto();
            if (res.data.data) {
                let item = (res.data.data);
                let data = item.img.data.data;
                const base64String = btoa(
                    new Uint8Array(data)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                const mimeType = res.headers['content-type']; // Make sure your backend sets this header
                let newVal = `data:${mimeType};base64,${base64String}`;
                dispatch({ type: 'GET_PROFILE_PIC', payload: newVal });
            }
        }
        // eslint-disable-next-line
    }, []);


    const fetchSessionUserData = useCallback(async () => {
        const userAssigned = sessionStorage.getItem('userAssigned');
        if (!userAssigned) {
            const session_id = sessionStorage.getItem('sessionId');
            const res = await updateSession(session_id, user.email || "");
            sessionStorage.setItem('userAssigned', true);
        }
    }, []);

    //Handlers
    const handleSelectedSettings = () => {
        if (!userDeleted) {
            setSelectedSection(4);
        }
        else {
            dispatch({ type: 'LOGOUT' });
        }
    }

    useEffect(() => {
        // Fetch on page load
        fetchProjects();
        // Fetch on page unload
        const handleUnload = () => fetchProjects();

        window.addEventListener('beforeunload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [fetchProjects,selectedSection]);


    useEffect(() => {
        const handlePageShow = (event) => {
            if (event.persisted) { // Checks if the page was loaded from cache
                // APIS 
                fetchProjects();
            }
        };
        window.addEventListener('pageshow', handlePageShow);
    
        return () => {
            window.removeEventListener('pageshow', handlePageShow);
        };
    }, []);



    useEffect(() => {
        fetchScreenshot();
    }, [fetchScreenshot])

    useEffect(() => {
        fetchProfilePic();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchSessionUserData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!localStorage.getItem('user')) {
            dispatch({ type: 'LOGOUT' });
        }
    });

    // Synchronize i18next with the state
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('languageCode')); // Update i18next when languageCode changes
    }, [languageCode]);

    return (
        <div className={styles.app}>
            <HStack>
                <div className={styles.sider}>
                    <VStack>
                        <Section style={{ background: 'rgba(200, 200, 200, 0.15)' }}>
                            <Flex justify='space-between' align='center' gap={10}>
                                <ProfileAvatar letter={user.username.charAt(0)} size={40} src={src} />
                                <span> {user.username ?? user.email} </span>
                                <Tooltip placement='bottom' title={t('settings')}>
                                    <Button
                                        icon={<SettingsIcon />}
                                        onClick={handleSelectedSettings}
                                        type='text'
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    />
                                </Tooltip>


                            </Flex>
                        </Section>
                        <Menu items={items} pickFunction={setSelectedSection} picked={selectedSection} />
                    </VStack>
                </div>
                <VStack>
                    {selectedSection === 4 && (
                        <div className={styles.settings}>
                            <VStack>
                                <SettingsForm />
                            </VStack>
                        </div>
                    )}
                    {selectedSection !== 4 && (
                        <>
                            {selectedSection === 0 && <div className={styles.getStarted}>
                                <ActionPanel
                                    title={t('getstarted')}
                                    description={t("actionpanelmessage")}
                                />
                            </div>}
                            <div className={styles.myProjects}>
                                {selectedSection === 0 &&
                                    <CardPanel
                                        title={t("myprojects")}
                                        items={projects.filter((item) => !item.is_archived).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))}
                                        screenshots={screenshots}
                                        setScreenshots={setScreenshots}
                                    />}
                                {selectedSection === 1 && <VideoPanel title={t("tutorials")} items={videos} />}
                                {selectedSection === 2 && <CardPanel
                                    title={t("archive")}
                                    items={projects.filter((item) => item.is_archived)}
                                    screenshots={screenshots}
                                    setScreenshots={setScreenshots}
                                />}
                                {selectedSection === 3 && <PaymentMenu/>}
                            </div>
                        </>
                    )}
                </VStack>
            </HStack>
        </div>
    )
}
