import { Menu } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './rightClickMenu.module.css';
import {
  DeleteShortcut, CutShortcut, CopyShortcut, PasteShortcut,
  ZIndexIncreaseShortcut, ZIndexDecreaseShortcut,
  HFlip, VFlip,
  GroupShortcut,
  UngroupShortcut,
  RotateShortcut
} from '../common/icons/shortcutIcons/shortcutIcons';
import { detectItemCollision } from '../../functions/zoneFinder';
import { useTranslation } from 'react-i18next';
import { getMenuItemColor } from '../../functions/menuaction/menuactions';
import { isSelectedItemRotatable } from '../../functions/helper';


function RightClickMenu({ visible, position, onClose, selectedItemIndexes, handleDelete, cut, copy, paste, copyItems, groupedItems, selectedItem, items, transform, handleVerticalChange, handleHorizontalChange, zIndexIncrase, zIndexDecrease, handleGroup, handleUngroup, hiddenItems, handeHide, handleUnhide, handleRotate90, handleItemRotate }) {

  const { t } = useTranslation();
  const [hoverIndex,setHoverIndex] = useState(null);

  const handleClickOutside = (e) => {
    if (visible) {
      onClose();
    }
  };


  useEffect(() => {
    // Attach the event listener to detect clicks outside the menu
    document.addEventListener('onMouseUp', handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('onMouseUp', handleClickOutside);
    };
  }, [visible]);

  const collidedItems = useMemo(() => {
    let arr = [];
    if (selectedItem) {
      items.forEach((item) => {
        if (detectItemCollision(item, selectedItem) && item.id !== selectedItem.id) {
          arr.push(item);
        }
      });
    }
    return arr;
  }, [items, selectedItem, transform]);

  const isSelectedItemOnTop = useMemo(() => {
    if (selectedItem && collidedItems.length > 0) {
      // Find the maximum zIndex among the collidedItems
      const maxZIndex = Math.max(...collidedItems.map(item => item.zIndex));

      if (selectedItem.zIndex > maxZIndex) {
        return true; // Selected item has a higher zIndex
      } else if (selectedItem.zIndex === maxZIndex) {
        // If zIndexes are equal, compare their index positions in the items array
        const selectedItemIndex = items.findIndex(item => item.id === selectedItem.id);
        const maxZIndexItems = collidedItems.filter(item => item.zIndex === maxZIndex);
        const maxZIndexItemIndex = Math.max(...maxZIndexItems.map(item => items.findIndex(i => i.id === item.id)));

        return selectedItemIndex > maxZIndexItemIndex;
      } else {
        return false; // Selected item has a lower zIndex
      }
    }
    return false;
  }, [selectedItem, collidedItems, items]);

  const isSelectedItemOnBottom = useMemo(() => {
    if (selectedItem && collidedItems.length > 0) {
      // Find the minimum zIndex among the collidedItems
      const minZIndex = Math.min(...collidedItems.map(item => item.zIndex));

      if (selectedItem.zIndex < minZIndex) {
        return true; // Selected item has a lower zIndex
      } else if (selectedItem.zIndex === minZIndex) {
        // If zIndexes are equal, compare their index positions in the items array
        const selectedItemIndex = items.findIndex(item => item.id === selectedItem.id);
        const minZIndexItems = collidedItems.filter(item => item.zIndex === minZIndex);
        const minZIndexItemIndex = Math.min(...minZIndexItems.map(item => items.findIndex(i => i.id === item.id)));

        return selectedItemIndex < minZIndexItemIndex;
      } else {
        return false; // Selected item has a higher zIndex
      }
    }
    return false;
  }, [selectedItem, collidedItems, items]);


  const isItemInGroup = useMemo(() => {
    let id = selectedItem?.id || "";
    let bool = true;
    for (let group of groupedItems) {
      if (group.includes(id)) {
        bool = false;
      }
    }
    return bool;
  }, [selectedItem, groupedItems]);




  const menuItems = useMemo(() => {

    return [
      { text: t("cut"), func: () => cut(selectedItem), disabled: !selectedItem, icon: (<CutShortcut color={hoverIndex === 0 ? 'white' : getMenuItemColor(!selectedItem)} />) },
      { text: t("copy"), func: () => copy(selectedItem), disabled: !selectedItem, icon: (<CopyShortcut color={hoverIndex === 1 ? 'white' : getMenuItemColor(!selectedItem)}/>) },
      { text: t("paste"), func: () => paste(), disabled: copyItems.length === 0, icon: (<PasteShortcut color={hoverIndex === 2 ? 'white' : getMenuItemColor(copyItems.length === 0)}/>) },
      { text: t("delete"), func: () => handleDelete(), disabled: !(selectedItem && selectedItemIndexes.length > 0), icon: (<DeleteShortcut color={hoverIndex === 3 ? 'white' : getMenuItemColor(!(selectedItem && selectedItemIndexes.length > 0))}/>) },
      { text: "Divider" },
      { text: t("Bring to Front"), func: () => zIndexIncrase(), disabled: (isSelectedItemOnTop || collidedItems.length <= 0), icon: (<ZIndexIncreaseShortcut color={hoverIndex === 5 ? 'white' : getMenuItemColor((isSelectedItemOnTop || collidedItems.length <= 0))}/>) },
      { text: t("Send to Back"), func: () => zIndexDecrease(), disabled: (isSelectedItemOnBottom || collidedItems.length <= 0), icon: (<ZIndexDecreaseShortcut color={hoverIndex === 6 ? 'white' : getMenuItemColor((isSelectedItemOnBottom || collidedItems.length <= 0))}/>) },
      { text: "Divider" },
      { text: t("Group"), func: () => handleGroup(), disabled: selectedItemIndexes?.length < 2 || !isItemInGroup, icon: (<GroupShortcut color={hoverIndex === 8 ? 'white' : getMenuItemColor(selectedItemIndexes?.length < 2 || !isItemInGroup)}/>) },
      { text: t("Ungroup"), func: () => handleUngroup(), disabled: groupedItems.length === 0 || selectedItemIndexes.length < 2, icon: (<UngroupShortcut color={hoverIndex === 9 ? 'white' : getMenuItemColor(groupedItems.length === 0 || selectedItemIndexes.length < 2)}/>) },
      { text: "Divider" },
      { text: t("Flip Horizontal"), func: () => handleHorizontalChange(), disabled: !(selectedItem && selectedItemIndexes.length === 1), icon: (<VFlip color={hoverIndex === 11 ? 'white' : getMenuItemColor(!(selectedItem && selectedItemIndexes.length === 1))}/>) },
      { text: t("Flip Vertical"), func: () => handleVerticalChange(), disabled: !(selectedItem && selectedItemIndexes.length === 1), icon: (<HFlip color={hoverIndex === 12 ? 'white' : getMenuItemColor(!(selectedItem && selectedItemIndexes.length === 1))}/>) },
      { text: t("Rotate"), func: () => handleItemRotate(), disabled: !(selectedItem && selectedItemIndexes.length === 1), icon: (<RotateShortcut color={hoverIndex === 13 ? 'white' : getMenuItemColor(!(selectedItem && selectedItemIndexes.length === 1))}/>) }
      //{ text: "Rotate 90 Degrees", func: (e) => handleRotate90(e), disabled: false, icon: (null) },
      //{ text: "Hide", func: () => handeHide(), disabled: selectedItemIndexes.length === 0 || !selectedItem, icon: (null) },
      //{ text: "Unhide", func: () => handleUnhide(), disabled: hiddenItems.length === 0, icon: (null) },
    ]
  }, [selectedItemIndexes, selectedItem, transform,hoverIndex,t]);

  return (
    <Menu
      theme='light'
      style={{
        overflowY: 'auto',
        scrollbarWidth: 'none',
        borderRadius: '8px',
        boxShadow: '#0000002E', // Adds a subtle shadow
        position: 'absolute',
        top: Math.min(
          (position?.y && position.y > 260 ? position.y - 260 : position?.y || 0),
          window.innerHeight - 450 // Prevents the menu from exceeding the screen height
        ),
        left: (position?.x || 0) + 30,
        display: visible ? 'block' : 'none',
      }}
      onClick={onClose}
    >
      <ul style={{
        padding:'8px 10px',
      }}>
      {menuItems.map((item, index) => (
        item.text === 'Divider' ? (
          <Menu.Divider
            key={`divider-${index}`}
          />
        ) : (
          <li
            key={(index + 1).toString()}
            disabled={item.disabled}
            className={!item.disabled ? styles.menuItemCustom : styles.disabled}
            onMouseEnter={() => {
              if(!item.disabled)
                setHoverIndex(index)
              }}
            onMouseLeave={() => setHoverIndex(null)}
            onMouseDown={(e) => {
              item.func(e);
              onClose();
            }}
          >
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 28
              //color:'#3E3E3E'
            }}>
              {item.text}
              {item.icon}
            </div>
          </li>
        )
      ))}
      </ul>
      

    </Menu>
  );
}

export default RightClickMenu
