import React, { useEffect, useMemo, useState, } from 'react'
import Toolbar from '../common/toolbar';

import './topbar.css'
import { CenterIcon, CopyIcon, CutIcon, DrawIcon, MinusIcon, PasteIcon, PlusIcon, RedoIcon, ScreenShotIcon, SelectIcon, TrashIcon, UndoIcon, LockWallsIcon, MeasurementIcon, ActionIcon, ActionShortcut, LockUnlockWallIconShortcut, ShortcutShortCut } from '../common/icons/icons';
import { Button, Divider, Flex, Menu, message, Popover, Switch, Tooltip } from 'antd';
import { CopyShortcut, CutShortcut, DeleteShortcut, DrawWallsShortcut, GroupShortcut, HFlip, LockWallsShortCut, PasteShortcut, RedoShortcut, RotateShortcut, Screenshotshortcut, SelectIconShortcut, UndoShortcut, UngroupShortcut, VFlip, ZIndexDecreaseShortcut, ZIndexIncreaseShortcut, ZoomInShortcut, ZoomOutShortcut, ZoomResetShortcut } from '../common/icons/shortcutIcons/shortcutIcons';
import { useTranslation } from 'react-i18next';
import { getMenuItemColor } from '../../functions/menuaction/menuactions';
import ActionMenu from '../actionmenu';
import Topbarswitch from '../common/topbarswitch';
import { DeleteSCIcon } from '../common/icons/shortcutpanelIcons.js/scpanelIcons';
import { ActionTBShortcut, CopyTBShortcut, CutTBShortcut, DeleteTBShortcut, DrawWallsTBShortcut, PasteTBShortcut, RedoTBShortcut, ScreenshotTBshortcut, SelectIconTBShortcut, UndoTBShortcut, ZoomInTBShortcut, ZoomOutTBShortcut, ZoomResetTBShortcut } from '../common/icons/topbarIcons/topbarIcons';

const BarDivider = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="26" viewBox="0 0 2 26" fill="none">
            <path opacity="0.5" d="M1 1L1 25" stroke="#7B7B7B" strokeLinecap="round" />
        </svg>
    )
}

const PercentageZoom = ({ transform, setZoom }) => {

    const { t } = useTranslation();

    const handleZoom = zoomFunc => {
        setZoom(zoomFunc, () => transform.degree, window.innerWidth / 2, window.innerHeight / 2);
    };


    useEffect(() => {
        const handleKeyDownZoom = (event) => {
            if (event.ctrlKey) {
                if (event.key === '0') {
                    handleZoom(zoom => 1)
                }
            }
        };

        window.addEventListener('keydown', handleKeyDownZoom);

        return () => {
            window.removeEventListener('keydown', handleKeyDownZoom);
        };
    }, []); // Make sure to include undo and redo functions in the dependencies array

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '2px'
        }}>
            <Tooltip placement="bottom" title={
                <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                    {t('zoomOut')}
                    <ZoomOutTBShortcut/>
                </span>
            }>
                <Button icon={<MinusIcon />} type="text" iconPosition={"center"} onClick={() => handleZoom(zoom => zoom / 1.1)} />
            </Tooltip>
            <span style={{ width: 'auto', height: 'auto', fontSize: '11px' }}> {Math.floor(transform.scale * 100)}% </span>
            <Tooltip placement="bottom" title={
                <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                    {t('zoomIn')}
                    <ZoomInTBShortcut/>
                </span>
            }>
                <Button icon={<PlusIcon />} type="text" iconPosition={"center"} onClick={() => handleZoom(zoom => zoom * 1.1)} />
            </Tooltip>
            <Tooltip placement="bottom" title={
                <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                    {t('zoomReset')}
                    <ZoomResetTBShortcut/>
                </span>
            }>
                <Button icon={<CenterIcon />} type="text" onClick={() => handleZoom(zoom => 1)} />
            </Tooltip>
        </div>
    );
};





export default function Topbar({ mode, setMode, deleteItems, undo, redo, cut, copy, paste, copyItem, selectedItem, captureScreenshot, transform, setZoom, setShowMeasurements, selectedWall, setDeleteState, selectedItemIndexes, lockedWallsState, setLockedWallsState, items, groupedItems, ActionMenuActions, showMeasurements }) {
    // Hooks
    const [actionMenuOpen, setActionMenuOpen] = useState(false);
    const [arrow, setArrow] = useState('Show');
    const [copied, setCopied] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [hoverIndex, setHoverIndex] = useState(-1);
    const { t } = useTranslation();

    /**
     * Tooltip Action
     */
    const [actionTooltipOpen,setActionTooltipOpen] = useState(false);

    const buttonStyle = (selectedMode) => ({
        backgroundColor: selectedMode === mode ? '#bfa1ff' : 'transparent',
        borderRadius: '4px', // Optional: add a border radius for a button-like appearance
        border: '1px solid transparent', // Optional: keep consistent button structure
        transition: 'background-color 0.3s ease', // Smooth transition for background change
    });

    // Hover effect styling
    const buttonHoverStyle = (selectedMode) => ({
        backgroundColor: selectedMode === mode ? '#bfa1ff' : '#f0f0f0', // Slight change on hover
        color: selectedMode === mode ? '#fff' : '#000',
        cursor: 'pointer',
    });

    const handleUndo = () => {
        undo();
    }

    const handleRedo = () => {
        redo();
    };

    const handleCut = () => {
        if (selectedItem) {
            setCopied(true);
            cut(selectedItem);
        }

    }

    const handleCopy = () => {
        if (selectedItem) {
            setCopied(true);
            copy(selectedItem);
        }
    }

    const handlePaste = () => {
        setCopied(false);
        paste();
    }

    const handleDelete = () => {
        if (selectedItem) {
            deleteItems();
        }
        else if (selectedWall !== null) {
            setDeleteState(true)
        }
        else {
            console.log("Please select an item first");
        }

    }
    const handleLockWalls = () => {
        if (lockedWallsState)
            setLockedWallsState(false);
        else
            setLockedWallsState(true);
    }
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }

        if (arrow === 'Show') {
            return true;
        }
    });



    useEffect(() => {
        const handleKeyDownSS = (event) => {

            if (event.ctrlKey || event.metaKey) {
                if (event.key === 'q' || event.key === 'Q') {
                    captureScreenshot();
                }
                else if(event.key === 'a' || event.key === 'A'){
                    event.preventDefault();
                    setActionMenuOpen(!actionMenuOpen);
                }
                else if(event.key === 'l' || event.key === 'L'){
                    event.preventDefault();
                    setLockedWallsState(!lockedWallsState);
                    if(lockedWallsState){
                        message.info(t("Walls Unlocked"));
                    }
                    else{
                        message.info(t("Walls Locked"));
                    }
                    
                }
                else if(event.shiftKey && event.key === 'k' || event.key === 'K'){
                    ActionMenuActions.shortcutOpenClose();
                }
                else if(event.key === 'm' || event.key === 'M'){
                    setShowMeasurements(!showMeasurements);
                }
            }
        };

        window.addEventListener('keydown', handleKeyDownSS);

        return () => {
            window.removeEventListener('keydown', handleKeyDownSS);
        };
    }, [actionMenuOpen,lockedWallsState,ActionMenuActions,showMeasurements]); // Make sure to include undo and redo functions in the dependencies array

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.ctrlKey || event.metaKey) {
                if (event.key === 'c' || event.key === 'C') {
                    handleCopy();
                }
                else if (event.key === 'x' || event.key === 'X') {
                    handleCut();
                }
                else if (event.key === 'v' || event.key === 'V') {
                    handlePaste();

                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [copyItem, selectedItem, selectedItemIndexes, transform]);

    useEffect(() => {
        // Function to handle window resize and update screenWidth state
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return (
        <Toolbar>
            <Flex justify='space-evenly' align='center' gap={screenWidth > 1024 ? '1vw' : '0.1vw'}>
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        {t('Select')}
                        <SelectIconTBShortcut/>
                    </span>
                } arrow={mergedArrow}>
                    <Button
                        icon={<SelectIcon />}
                        style={buttonStyle("Hand")}
                        type="text"
                        iconPosition={"center"}
                        onClick={() => setMode("Hand")}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle("Hand").backgroundColor}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = buttonStyle("Hand").backgroundColor}
                    />
                </Tooltip>
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        {t('drawLine')}
                        <DrawWallsTBShortcut />
                    </span>
                } arrow={mergedArrow}>
                    <Button
                        icon={<DrawIcon />}
                        type="text"
                        style={buttonStyle("Draw")}
                        iconPosition={"center"}
                        onClick={() => setMode("Draw")}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle("Draw").backgroundColor}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = buttonStyle("Draw").backgroundColor}
                    />

                </Tooltip>
                <BarDivider />
                {/* Undo, Redo */}
                <Tooltip
                    placement="bottom"
                    title={
                        <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                            {t('undo')}
                            <UndoTBShortcut />
                        </span>
                    }
                    arrow={mergedArrow}
                >
                    <Button icon={<UndoIcon />} type="text" iconPosition={"center"} onClick={() => handleUndo()} />
                </Tooltip>
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        {t('redo')}
                        <RedoTBShortcut />
                    </span>
                } arrow={mergedArrow}>
                    <Button icon={<RedoIcon />} type="text" iconPosition={"center"} onClick={() => handleRedo()} />
                </Tooltip>
                <BarDivider />
                {/*Cut,Copy, Paste */}
                <Tooltip
                    placement="bottom"
                    title={
                        <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                            {t('cut')}
                            <CutTBShortcut color={'rgba(170, 170, 170, 0.5)'}/>
                        </span>
                    }
                    arrow={mergedArrow}
                >
                    <Button icon={<CutIcon disabled={copied || !selectedItem} />} type="text" iconPosition={"center"} onClick={handleCut} disabled={copied || !selectedItem} style={{
                        cursor: 'pointer'
                    }} />
                </Tooltip>
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        {t('copy')}
                        <CopyTBShortcut color={'rgba(170, 170, 170, 0.5)'}/>
                    </span>
                } arrow={mergedArrow}>
                    <Button icon={<CopyIcon disabled={copied || !selectedItem} />} type="text" iconPosition={"center"} onClick={handleCopy} disabled={copied || !selectedItem} style={{
                        cursor: 'pointer'
                    }} />
                </Tooltip>
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        {t('paste')}
                        <PasteTBShortcut color={'rgba(170, 170, 170, 0.5)'}/>
                    </span>
                } arrow={mergedArrow}>
                    <Button icon={<PasteIcon disabled={!copied} />} type="text" iconPosition={"center"} onClick={handlePaste} disabled={!copied} style={{
                        transition: '0.3s ease',
                        cursor: 'pointer'
                    }} />
                </Tooltip>
                <BarDivider />
                {/* Garbage Can */}
                <Tooltip placement="bottom" title={
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        {t('delete')}
                        <DeleteTBShortcut color={'rgba(170, 170, 170, 0.5)'} />
                    </span>
                } arrow={mergedArrow}>
                    <Button icon={<TrashIcon disabled={(selectedItem === null) && selectedWall === undefined} />}
                        type="text" iconPosition={"center"}
                        onClick={handleDelete}
                        onTouchStart={handleDelete}
                        disabled={selectedItem === null && selectedWall === undefined}
                        style={{
                            cursor: 'pointer'
                        }}
                    />
                </Tooltip>
                <BarDivider />
                {/* ScreenShot*/}
                {screenWidth > 1000 && <Tooltip placement="bottom"
                    title={
                        <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                            {t('screenshot')}
                            <ScreenshotTBshortcut/>
                        </span>
                    } arrow={mergedArrow}>
                    <Button icon={<ScreenShotIcon />} type="text" iconPosition={"center"} onClick={captureScreenshot} onTouchStartCapture={captureScreenshot} />
                </Tooltip>}
                {screenWidth > 1000 && <BarDivider />}
                {/* Zoom Bar */}
                <PercentageZoom transform={transform} setZoom={setZoom} />
                <BarDivider />
                <Tooltip placement='bottom' title={t('Show Measurements')}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.3vw'
                    }}>

                        <Topbarswitch switchFunction={setShowMeasurements} showMeasurements = {showMeasurements}/>

                        {/*<Switch
                        onChange={setShowMeasurements}
                        size={screenWidth > 1180 ? 'medium' : 'small'}
                        checkedChildren={<MeasurementIcon />}
                        iconPosition={'center'}
                    />*/}
                    </div>
                </Tooltip>

                <BarDivider />
                <Tooltip placement="bottom" title={actionMenuOpen ? '' :
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', gap: '0.6em' }}>
                        {t('toActions')}
                        <ActionTBShortcut/>
                    </span>
                } arrow={mergedArrow} open={actionTooltipOpen}>
                    <Popover
                        overlayStyle={{
                            top: 63
                        }}
                        arrow={false}
                        content={
                            <ActionMenu
                                selectedItem={selectedItem}
                                transform={transform}
                                selectedItemIndexes={selectedItemIndexes}
                                handleLockWalls={() => {
                                    handleLockWalls(!lockedWallsState);
                                    if(lockedWallsState){
                                        message.info(t("Walls Unlocked"));
                                    }
                                    else{
                                        message.info(t("Walls Locked"));
                                    }
                                }}
                                lockedWallsState={lockedWallsState}
                                items={items}
                                groupedItems={groupedItems}
                                ActionMenuActions = {{
                                    ...ActionMenuActions,
                                    close : () => setActionMenuOpen(false)
                                }}
                            />
                        }
                        trigger="click"
                        open={actionMenuOpen}
                    >
                        <Button
                            icon={<ActionIcon />}
                            type="text"
                            className={actionMenuOpen ? 'actionMenu' : ''}
                            iconPosition={"center"}
                            onClick={() =>{ 
                                setActionMenuOpen(!actionMenuOpen);
                                setActionTooltipOpen(false);
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = buttonHoverStyle("Hand").backgroundColor;
                                setActionTooltipOpen(true);
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = 'transparent';
                                setActionTooltipOpen(false);
                            }}
                        />
                    </Popover>
                </Tooltip>

            </Flex>
        </Toolbar>
    )
}
