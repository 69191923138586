import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Group, Layer, Line, Transformer, Circle, Text, Rect, Arc } from 'react-konva';
import { useDebounce } from '../../hooks/asyncHooks/useDebounce';
import { useProjectAPI } from '../../hooks/apiHooks/projectHooks/useProjectAPI';
import DragItem from '../item/dragItem';
import { message } from 'antd';
import { useSnapshotContext } from '../../hooks/contextHooks/useSnapshotContext';
import cloneDeep from 'lodash/cloneDeep';
import rotateImg from '../../assets/helper-img/rotate.png';
import WallComponent from '../wall/wall';
import Polygon from '../polygon/polygon';
import { findAdjacencyList, findChordlessCycles, findPointList, shrinkPoints } from '../../functions/zoneFinder';
import AreaText from '../areaText';
import { useFloorContext } from '../../hooks/contextHooks/useFloorContext';
import { useSettingContext } from '../../hooks/contextHooks/useSettingsContext';
import { convertMetersToFeet, getCombinedBoundingBox, isSelectedItemRotatable } from '../../functions/helper';
import { useAuthContext } from '../../hooks/contextHooks/useAuthContext';
import { useTranslation } from 'react-i18next';
import { useLngContext } from '../../hooks/contextHooks/useLngContext';


message.config({
    top: window.innerHeight - 100,
    duration: 2, // Duration in seconds
    style: {
        marginLeft: '20px', // Adjust horizontal margin
    },
});

const RoomLayer = ({ dragX = 0, dragY = 0, offsetX = 0, offsetY = 0, scale = 1, degree = 0, walls = [], points = [], stroke, thickness, projectId, items, index, setItems, topBarMode, selectedItem, setSelectedItem, setSelectedItemIndex, selectedWall, setSelectedWall, shapeRefs, clickedPos, onPanel, history, showMeasurements, isDrawing, movingWall, selectedWallId, cursorPosition, wallsRef, selectedFloor, setSelectedFloor, floors, setFloors, isDrawingState, selectedItemIndexes, setSelectedItemIndexes, clickedItem, setClickedItem, groupedItems, hiddenItems, handleItemRotate, lockedWallsState, rightClicked, setRightClicked, ...props }) => {
    const [lines, setLines] = useState([]);
    const [dragCount, setDragCount] = useState(0);
    const [isRotating, setIsRotating] = useState(false);
    const [rotationValue, setRotationValue] = useState(0);
    const [rotateEnabled, setRotateEnabled] = useState(false);
    const [rotateImgUrl, setRotateImgUrl] = useState(rotateImg);

    const { floorContext, floorDispatch, floorTrigger } = useFloorContext();
    const {languageCode} = useLngContext();
    // 2D Array for storing the point lists for floors
    const [pointList, setPointList] = useState([]);
    // Rotation
    const [selectedIndex, setSelectedIndex] = useState(-1);
    // Ref Idea
    const { snapshot, snapshotDispatch } = useSnapshotContext();
    const transformerRef = useRef(null);
    // const pointsState = useState(points)
    //let oldAttrs;
    const { updateProject, saveSnapshotToDb, getSnapshotDataPrev, saveLastStateImage } = useProjectAPI();
    const { measurementSystem } = useSettingContext();
    const [isCtrlPressed, setIsCtrlPressed] = useState(false);

    const [draggedItem, setDraggedItem] = useState(false);
    const { user } = useAuthContext();

    const [measureLines, setMeasureLines] = useState([]);
    const [previousSnapshots, setPreviousSnapshots] = useState([]);
    const [snapDataLoaded, setSnapDataLoaded] = useState(false);
    const { t } = useTranslation();


    const viewPort = {
        left: -5000,
        top: -5000,
        right: 5000,
        bottom: 5000,
    };

    let lastX = null;
    let lastY = null;

    let usedPoints = [];
    for (const wall of walls) {
        usedPoints.push(wall.points.start.id); 
        usedPoints.push(wall.points.end.id); 
    }
    const handleSavePoints = useDebounce(async (value) => {
        await updateProject(projectId, { room_points: value });
        message.info(t('Project automatically saved'));
    }, 10000);

    const handleSaveItems = useCallback(
        useDebounce(async (value) => {
            await updateProject(projectId, { room_items: value });
            //message.info('Project automatically saved.');
        }, 10000), [projectId]);

    const handleSaveFloors = useCallback(
        useDebounce(async (value) => {
            await updateProject(projectId, { room_floors: value });
            //message.info('Project automatically saved.');
        }, 10000),
        [projectId]
    );

    const handleSaveGroups = useCallback(
        useDebounce(async (value) => {
            await updateProject(projectId, { room_groups: value });
        }, 10000),
        [projectId]
    );

    const handleSaveSnapshots = useCallback(useDebounce(async (value) => {
        await saveSnapshotToDb(projectId, user.userIdMongo, value);
    }, 10000),
        [projectId]);

    
    const handleSaveLastStata = useCallback(useDebounce(async () => {
        const targetDiv = document.getElementById('whiteboard-content');
        if(projectId){
            await saveLastStateImage(projectId,user.userIdMongo,targetDiv);
        }
        
    },1000),[walls,items,points]);

    const handleSaveHiddenItems = useCallback(
        useDebounce(async (value) => {
            await updateProject(projectId, { room_hidden_items: value });
        }, 10000),
        [projectId]
    );

    /*
    const handleSaveLockedWall = useCallback(
        useDebounce( async (value) => {
            await updateProject(projectId, { room_locked_wall: value });
        }, 10000),
        [projectId]
    );
    */


    const handleSave = useDebounce(async (value, type) => {
        if (type === 'walls') {
            await updateProject(projectId, { room_shape: value });
        }
    }, 10000);


    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            setSelectedIndex(-1);
            setSelectedItem(null);
            setSelectedItemIndexes([]);
        }
    }
    const addLines = () => {
        const updatedLines = [];
        let line = [];
        for (const wall of walls) {
            line = [wall.points.start.x, wall.points.start.y, wall.points.end.x, wall.points.end.y];
            updatedLines.push({ points: line, thickness: wall.thickness, color: wall.color, length: wall.length, id: wall.id });
            lastX = wall.points.end.x;
            lastY = wall.points.end.y;
            usedPoints.push(wall.points.start.id); 
            usedPoints.push(wall.points.end.id); 
        }
        // console.log(usedPoints);
        return updatedLines;
    }
    const handleTransform = () => {
        {/* const selectedShapeRef = shapeRefs.current[selectedIndex]?.current;
        if (selectedShapeRef) {
            const box = selectedShapeRef.getClientRect();
            const isOut =
                box.x < 0 ||
                box.y < 0 ||
                box.x + box.width > window.innerWidth ||
                box.y + box.height > window.innerHeight;
            if (isOut) {
                selectedShapeRef.setAttrs(oldAttrs);
            } else {
                oldAttrs = { ...selectedShapeRef.getAttrs() };
                const updatedItems = [...items];
                updatedItems[selectedIndex].rotation = oldAttrs.rotation;
                updatedItems[selectedIndex].x = oldAttrs.x;
                updatedItems[selectedIndex].y = oldAttrs.y;
                setItems(updatedItems);
            }
        }*/}
    };

    const handleSelect = (index) => {
        setSelectedWall(null);
        setSelectedIndex(index);
        setSelectedItemIndex(index);
    }

    const handleUnselect = () => {
        if (selectedItem) {
            setSelectedItem(null);
            setSelectedIndex(-1);
            setSelectedItemIndex(-1);
        }
    }


    useEffect(() => {
        if (transformerRef.current === null) {
            return;
        }
        let newSelectedItemIndexes = [];
        if (groupedItems.some(group => group.includes(items[selectedIndex]?.id))) {
            // If the selected item is part of a group
            const group = groupedItems.find(group => group.includes(items[selectedIndex]?.id));

            if (group) {
                // Map the group of item IDs back to their corresponding indexes in the items array
                const indexes = group.map(id => items.findIndex(item => item.id === id));
                // Get the nodes corresponding to the indexes
                const nodes = indexes.map(index => shapeRefs.current[index]?.current).filter(Boolean);
                transformerRef.current.nodes(nodes);
                newSelectedItemIndexes = group.map(id => items.findIndex(item => item.id === id));
                //setSelectedItemIndexes(indexes);
            }

        }
        else if (selectedItemIndexes.length > 0) {
            // If multiple items are selected
            const nodes = selectedItemIndexes.map(index => shapeRefs.current[index]?.current).filter(Boolean);

            transformerRef.current.nodes(nodes);
            newSelectedItemIndexes = selectedItemIndexes;


        } else if (shapeRefs.current[selectedIndex]) {
            // If only one item is selected
            transformerRef.current.nodes([shapeRefs.current[selectedIndex].current]);
            newSelectedItemIndexes = [selectedIndex];
        }

        transformerRef.current.getLayer().batchDraw();

        // Prevent infinite loop by only setting selected item indexes if they differ
        if (newSelectedItemIndexes.length !== selectedItemIndexes.length ||
            !newSelectedItemIndexes.every((val, idx) => val === selectedItemIndexes[idx])) {
            setSelectedItemIndexes(newSelectedItemIndexes);
        }

        // Update selected item based on the first selected index or single selection
        setSelectedItem(items[selectedItemIndexes.length > 0 ? selectedItemIndexes[0] : selectedIndex]);
        // eslint-disable-next-line
    }, [selectedItemIndexes, selectedItem, selectedIndex, transformerRef, shapeRefs, items, groupedItems]);


    useEffect(() => {
        let editedLines = addLines();
        setLines(editedLines);
        handleSave(walls, "walls");
        // eslint-disable-next-line
    }, [walls, points]);

    useEffect(() => {
        handleSavePoints(points);
        //message.info('Project automatically saved.');
        // eslint-disable-next-line
    }, [walls, points]);

    useEffect(() => {
        handleSaveFloors(floorContext);
        // eslint-disable-next-line
    }, [floorContext]);

    useEffect(() => {
        handleSaveGroups(groupedItems);
        // eslint-disable-next-line
    }, [groupedItems]);


    useEffect(() => {
        handleSaveLastStata()
    },[handleSaveLastStata])

    /*
    useEffect(() => {
        handleSaveLockedWall(lockedWallsState);
    },[lockedWallsState]);
    */

    /*
    useEffect(() => {
        handleSaveHiddenItems(hiddenItems);
        // eslint-disable-next-line
    },[hiddenItems]);
    */
    useEffect(() => {
        if (!selectedItem) {
            setSelectedIndex(-1);
        }
    }, [selectedItem]);


    const getLineIntersection = (p1, p2, p3, p4) => {
        const s1_x = p2.x - p1.x;
        const s1_y = p2.y - p1.y;
        const s2_x = p4.x - p3.x;
        const s2_y = p4.y - p3.y;

        const denominator = (-s2_x * s1_y + s1_x * s2_y);

        if (denominator === 0) {
            return null;
        }

        const s = (-s1_y * (p1.x - p3.x) + s1_x * (p1.y - p3.y)) / denominator;
        const t = (s2_x * (p1.y - p3.y) - s2_y * (p1.x - p3.x)) / denominator;

        if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
            const intersectionX = p1.x + (t * s1_x);
            const intersectionY = p1.y + (t * s1_y);
            return {
                x: intersectionX,
                y: intersectionY
            };
        }

        return null;
    };


    /**
     * This UseEffect is used for
     * item unselect
     */
    useEffect(() => {

        const handleClick = (event) => {

            /**
             * Helper function to check if a point is inside a bounding box
             */
            function isOutsideBoundingBox(boundingBox, clickPos) {
                const { x, y, width, height } = boundingBox;
                return (
                    clickPos.x < x ||
                    clickPos.x > x + width ||
                    clickPos.y < y ||
                    clickPos.y > y + height
                );
            }


            if (transformerRef.current) {
                const boundingBox = transformerRef.current.getClientRect();
                if (!clickedItem && !draggedItem && !event.ctrlKey) {
                    if (isOutsideBoundingBox(boundingBox, clickedPos) && !rightClicked) {
                        handleUnselect();
                    }
                    setRightClicked(false);
                    
                }
            }
            else {
                // If somewhere that is in bounds with group have been clicked
                if (groupedItems.length > 0) {
                    groupedItems.forEach((group) => {
                        // Map the group of item IDs back to their corresponding indexes in the items array
                        const indexes = group.map(id => items.findIndex(item => item.id === id));
                        const nodes = indexes.map(index => shapeRefs.current[index]?.current).filter(Boolean);
                        // Set nodes to transformer
                        if (nodes.length > 0 && nodes) {
                            let { left, top, width, height } = getCombinedBoundingBox(nodes);
                            // Get click position
                            let { clientX, clientY } = event;

                            // Check if the click is inside the bounding box
                            const isInside = clientX >= left && clientX <= left + width &&
                                clientY >= top && clientY <= top + height;
                            if (isInside) {
                                console.log('Click is inside the bounding box');
                                handleSelect(indexes[0]);
                                setSelectedItemIndexes(indexes)
                            } else {
                                console.log('Click is outside the bounding box');
                            }
                        }

                    });
                }

            }
        };
        window.addEventListener('click', handleClick);
        return () => {
            setDraggedItem(false);
            window.removeEventListener('click', handleClick);
        };
        // eslint-disable-next-line
    }, [clickedPos, selectedWall, selectedFloor, clickedItem, items, selectedItemIndexes]);

    useEffect(() => {
        shapeRefs.current = items.map(() => React.createRef());
        handleSaveItems(items);
        // eslint-disable-next-line
    }, [items, selectedItem]);




    useEffect(() => {
        const handleButtonDown = (event) => {
            if ((event.key === 'r' || event.key === 'R') && selectedItem && selectedItem !== undefined && selectedItemIndexes.length === 1) {
                event.preventDefault();
                if(isSelectedItemRotatable(selectedItem)){
                    handleItemRotate();
                }
                
            }
        }

        window.addEventListener('keydown', handleButtonDown);

        return () => {
            window.removeEventListener('keydown', handleButtonDown);
        };
        // eslint-disable-next-line
    }, [items, selectedItem, selectedIndex]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleBeforeUnload = async (event) => {
            // Prevent the default behavior (confirmation dialog)
            //event.preventDefault();
            handleSaveLastStata();
            await updateProject(projectId, {
                room_shape: walls,
                room_items: items,
                room_points: points,
                room_floors: floorContext,
                room_groups: groupedItems,
                room_hidden_items: hiddenItems,
                room_locked_wall: lockedWallsState
            });
        };

        // Add event listener for beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove event listener when component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    });

    useEffect(() => {

    });

    /**
     * Gets the Initial Snapshot
     */
    useEffect(() => {
        const getSnap = async () => {
            const data = await getSnapshotDataPrev(projectId);
            setPreviousSnapshots(data.data.snapshots);
            setSnapDataLoaded(true);
        };
        getSnap();
    }, []);

    useEffect(() => {
        const saveSnap = async () => {
            if (snapDataLoaded) {
                await saveSnapshotToDb(projectId, user.userIdMongo, [...previousSnapshots?.slice(0, -1) ?? [], ...snapshot]);
            }
        };
        saveSnap();
    }, [snapshot, previousSnapshots]);


    /**
     * This UseEffect is used
     * for allowing the ctrl 
     * to be pressed and released
     */
    useEffect(() => {
        const handleMultipleSelectKeyDown = (event) => {
            if (event.ctrlKey || event.metaKey) {
                event.preventDefault();
                setRightClicked(false);
                setIsCtrlPressed(true);
            }
        };

        const handleMultipleSelectKeyUp = (event) => {
            // Logging the event to see if it triggers
            if (!event.ctrlKey && !event.metaKey) {
                setIsCtrlPressed(false);
            }
        };

        window.addEventListener('keydown', handleMultipleSelectKeyDown);
        window.addEventListener('keyup', handleMultipleSelectKeyUp);

        // Cleanup the event listeners on component unmount
        return () => {
            window.removeEventListener('keydown', handleMultipleSelectKeyDown);
            window.removeEventListener('keyup', handleMultipleSelectKeyUp);
        };
    }, []);

    const handleMultipleItemClick = (index) => {
        if (isCtrlPressed) {
            setSelectedItemIndexes(prevIndexes => {
                if (prevIndexes.includes(index)) {
                    // Remove the item from the selection if it's already selected
                    return prevIndexes.filter(i => i !== index);
                } else {
                    // Add the item to the selection
                    return [...prevIndexes, index];
                }
            });
        } else {
            // If Ctrl is not pressed, just select the clicked item
            setSelectedItemIndexes([index]);
            handleSelect(index);
        }
    };

    const adjList = useMemo(() => {
        return findAdjacencyList(walls, points);
        // eslint-disable-next-line
    }, [index, floorTrigger, floors]);


    const measureLinesMemo = useMemo(() => {
        const createDistanceLines = () => {
            const distanceLines = [];
            const { x, y, width, height, rotation } = selectedItem;

            const rotatePoint = (px, py, angle, cx, cy) => {
                const radians = (Math.PI / 180) * angle;
                const cos = Math.cos(radians);
                const sin = Math.sin(radians);
                const nx = cos * (px - cx) - sin * (py - cy) + cx;
                const ny = sin * (px - cx) + cos * (py - cy) + cy;
                return { x: nx, y: ny };
            };
            const toRadians = (angle) => (Math.PI / 180) * angle;


            const halfWidth = width / 2;
            const halfHeight = height / 2;

            // Convert rotation to radians for calculations
            const radians = (Math.PI / 180) * rotation;

            // Calculate each corner relative to the center, then rotate them directly
            const rotatedTopLeft = {
                x: x - halfWidth * Math.cos(radians) + halfHeight * Math.sin(radians),
                y: y - halfWidth * Math.sin(radians) - halfHeight * Math.cos(radians),
            };
            const rotatedTopRight = {
                x: x + halfWidth * Math.cos(radians) + halfHeight * Math.sin(radians),
                y: y + halfWidth * Math.sin(radians) - halfHeight * Math.cos(radians),
            };
            const rotatedBottomLeft = {
                x: x - halfWidth * Math.cos(radians) - halfHeight * Math.sin(radians),
                y: y - halfWidth * Math.sin(radians) + halfHeight * Math.cos(radians),
            };
            const rotatedBottomRight = {
                x: x + halfWidth * Math.cos(radians) - halfHeight * Math.sin(radians),
                y: y + halfWidth * Math.sin(radians) + halfHeight * Math.cos(radians),
            };

            const topMiddle = {
                x: (rotatedTopLeft.x + rotatedTopRight.x) / 2,
                y: (rotatedTopLeft.y + rotatedTopRight.y) / 2,
            };
            const rightMiddle = {
                x: (rotatedTopRight.x + rotatedBottomRight.x) / 2,
                y: (rotatedTopRight.y + rotatedBottomRight.y) / 2,
            };
            const bottomMiddle = {
                x: (rotatedBottomLeft.x + rotatedBottomRight.x) / 2,
                y: (rotatedBottomLeft.y + rotatedBottomRight.y) / 2,
            };
            const leftMiddle = {
                x: (rotatedTopLeft.x + rotatedBottomLeft.x) / 2,
                y: (rotatedTopLeft.y + rotatedBottomLeft.y) / 2,
            };
            const extendLine = (start, angle) => {
                const length = 10000;
                return {
                    x: start.x + length * Math.cos(angle),
                    y: start.y + length * Math.sin(angle),
                };
            };

            const itemEdges = [
                {
                    start: topMiddle,
                    end: extendLine(topMiddle, (rotation + 270) * (Math.PI / 180)),
                    direction: 'top',
                },
                {
                    start: rightMiddle,
                    end: extendLine(rightMiddle, (rotation) * (Math.PI / 180)),
                    direction: 'right',
                },
                {
                    start: bottomMiddle,
                    end: extendLine(bottomMiddle, (rotation + 90) * (Math.PI / 180)),
                    direction: 'bottom',
                },
                {
                    start: leftMiddle,
                    end: extendLine(leftMiddle, (rotation + 180) * (Math.PI / 180)),
                    direction: 'left',
                },
            ];

            const calculateDistance = (p1, p2) => {
                return Math.hypot(p2.x - p1.x, p2.y - p1.y);
            };

            const findClosestObject = (line, walls, items) => {
                const { start, end } = line;
                let closestDistance = Infinity;
                let closestPoint = null;

                walls.forEach((wall) => {
                    const wallStart = { x: wall.points.start.x, y: wall.points.start.y };
                    const wallEnd = { x: wall.points.end.x, y: wall.points.end.y };
                    const intersection = getLineIntersection(start, end, wallStart, wallEnd);

                    if (intersection) {
                        const distance = calculateDistance(start, intersection);
                        if (distance < closestDistance) {
                            closestDistance = distance;
                            closestPoint = intersection;
                        }
                    }
                });

                items.forEach((item) => {
                    if (item === selectedItem) return; // Skip the selected item itself

                    const { x, y, width, height, rotation } = item; // (x, y) is the center of the item

                    // Convert rotation to radians for calculations
                    const radians = (Math.PI / 180) * rotation;

                    // Calculate half dimensions for easier calculations
                    const halfWidth = width / 2;
                    const halfHeight = height / 2;

                    // Calculate each corner relative to the center, then rotate them
                    const itemTopLeft = {
                        x: x - halfWidth * Math.cos(radians) + halfHeight * Math.sin(radians),
                        y: y - halfWidth * Math.sin(radians) - halfHeight * Math.cos(radians),
                    };
                    const itemTopRight = {
                        x: x + halfWidth * Math.cos(radians) + halfHeight * Math.sin(radians),
                        y: y + halfWidth * Math.sin(radians) - halfHeight * Math.cos(radians),
                    };
                    const itemBottomLeft = {
                        x: x - halfWidth * Math.cos(radians) - halfHeight * Math.sin(radians),
                        y: y - halfWidth * Math.sin(radians) + halfHeight * Math.cos(radians),
                    };
                    const itemBottomRight = {
                        x: x + halfWidth * Math.cos(radians) - halfHeight * Math.sin(radians),
                        y: y + halfWidth * Math.sin(radians) + halfHeight * Math.cos(radians),
                    };

                    // Define the edges of the item using the rotated corners
                    const itemEdges = [
                        { start: itemTopLeft, end: itemTopRight },
                        { start: itemBottomLeft, end: itemBottomRight },
                        { start: itemTopLeft, end: itemBottomLeft },
                        { start: itemTopRight, end: itemBottomRight },
                    ];

                    // Check intersection with each item edge
                    itemEdges.forEach((edge) => {
                        const intersection = getLineIntersection(start, end, edge.start, edge.end);

                        if (intersection) {
                            const distance = calculateDistance(start, intersection);
                            if (distance < closestDistance) {
                                closestDistance = distance;
                                closestPoint = intersection;
                            }
                        }
                    });
                });

                return { closestDistance, closestPoint };
            };

            itemEdges.forEach((edge) => {
                const { closestDistance, closestPoint } = findClosestObject(edge, walls, items);
                if (closestPoint && closestDistance !== Infinity) {
                    distanceLines.push({
                        start: edge.start,
                        end: closestPoint,
                        distance: closestDistance.toFixed(0)
                    });
                }
            });

            return distanceLines;
        };
        if (!selectedItem) {
            return [];
        }
        else {
            const distanceLines = createDistanceLines();
            return distanceLines;
        }
    }, [selectedItem, items, walls])


    /**
     * This UseEffect is used for adjusting the room points
     */
    useEffect(() => {
        let adjacencyList = adjList
        let rooms = findChordlessCycles(adjacencyList, walls, points);
        const pointLists = rooms.map((room, index) => {
            // Step 1: Filter points that belong to the current room
            let roomPoints = points.filter(element => room.includes(element.id));
            let roomStr = findPointList(roomPoints);

            // Create a map for quick lookup of points by their ID
            let pointMap = new Map(roomPoints.map(point => [point.id, point]));

            // Step 2: Filter walls that are part of the current room
            let roomWalls = walls.filter(wall => room.includes(wall.startPointId) && room.includes(wall.endPointId));

            // Step 3: Create a map of connections
            let connections = new Map();
            roomWalls.forEach(wall => {
                if (!connections.has(wall.startPointId)) {
                    connections.set(wall.startPointId, []);
                }
                if (!connections.has(wall.endPointId)) {
                    connections.set(wall.endPointId, []);
                }
                connections.get(wall.startPointId).push(wall.endPointId);
                connections.get(wall.endPointId).push(wall.startPointId);
            });

            // Step 4: Determine the order of points based on the walls
            let orderedPoints = room;

            // Traverse the walls to find the order
            // while (visited.size < roomPoints.length) {
            //     orderedPoints.push(currentPointId);
            //     visited.add(currentPointId);

            //     let neighbors = connections.get(currentPointId).filter(id => !visited.has(id));
            //     if (neighbors.length > 0) {
            //         nextPointId = neighbors[0];
            //     } else {
            //         break;
            //     }

            //     currentPointId = nextPointId;
            // }

            // Step 5: Flatten the ordered points into an array of coordinates
            let result = [];
            orderedPoints.forEach(pointId => {
                let point = pointMap.get(pointId);
                if (point) {
                    result.push(point.x, point.y);
                }
            });
            let pointIdList = findPointList(roomPoints)
            return {
                points: result,
                pointIds: pointIdList,
                id: roomStr
            };
        });
        setPointList(pointLists);
        // eslint-disable-next-line
    }, [adjList]);

    const floorsMapped = useMemo(() => {
        return pointList.filter(Boolean).map((room, index) => {
            // Find if the room already exists in the floors array
            if (floorContext && room !== undefined) {
                let existingRoom = [...floorContext, ...floors].filter(Boolean).find(existing => existing.id === room.id);
                if (existingRoom) {
                    // Update the existing room
                    return {
                        ...existingRoom,
                        room_type: existingRoom.room_type,
                        floor_type: existingRoom.floor_type,
                        pointList: room.points
                    };

                } else {
                    // Create a new room
                    return {
                        id: room.id,
                        pointList: room.points,
                        room_type: 'Unnamed',
                        floor_type: '1',
                        orderedPoints: room.pointIds,
                    };
                }
            }
            else {
                return null;
            }
        });
        // eslint-disable-next-line
    }, [pointList]);

    useEffect(() => {
        floorDispatch({ type: 'GET_FLOORS', payload: floorsMapped });
        // eslint-disable-next-line
    }, [floorsMapped]);


    function mouseOnItems(click) {
        for (const item of items) {
            const { x, y, width, height, rotation } = item;

            const angleRad = ((rotation % 360) * Math.PI) / 180;
            const rotatePoint = (px, py, angleRad, originX, originY) => {
                const dx = px - originX;
                const dy = py - originY;
                return {
                    x: originX + dx * Math.cos(angleRad) - dy * Math.sin(angleRad),
                    y: originY + dx * Math.sin(angleRad) + dy * Math.cos(angleRad)
                };
            };

            const corners = [
                rotatePoint(x, y, angleRad, x, y),
                rotatePoint(x + width, y, angleRad, x, y),
                rotatePoint(x + width, y + height, angleRad, x, y),
                rotatePoint(x, y + height, angleRad, x, y)
            ];
            const triangleArea = (A, B, C) => {
                return 0.5 * Math.abs(
                    A.x * (B.y - C.y) +
                    B.x * (C.y - A.y) +
                    C.x * (A.y - B.y)
                );
            };
            const rectangleArea = triangleArea(corners[0], corners[1], corners[2]) +
                triangleArea(corners[2], corners[3], corners[0]);
            const totalArea = triangleArea(corners[0], corners[1], click) +
                triangleArea(corners[1], corners[2], click) +
                triangleArea(corners[2], corners[3], click) +
                triangleArea(corners[3], corners[0], click);
            if (Math.abs(totalArea - rectangleArea) < 1) {
                return item;
            }
        }
        return false;
    };

    function lineLength(x1, y1, x2, y2) {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    }

    const orderedItems = useMemo(() => {
        return items.sort((a, b) => a.zIndex - b.zIndex).filter((item) => !hiddenItems.includes(item.id));
    }, [items, hiddenItems]);

    return (
        <Layer width={viewPort.right - viewPort.left} height={viewPort.bottom - viewPort.top} rotation={degree} key={0} >
            {floorsMapped.map((item, index) => (
                <div key={index}>
                    {<Polygon
                        key={index}
                        index={item.id}
                        pointList={item.pointList}
                        selectedFloor={selectedFloor}
                        setSelectedFloor={setSelectedFloor}
                        showMeasurements={showMeasurements}
                        onPanel={onPanel}
                        floors={floors}
                    />
                    }
                </div>
            ))}
            {points.map((point, index) => {
                let tempPoints = [];
                let lastLine = null;
                let selectedLine = null;
                let newLine = null;
                let thickness = 10;
                let color = '';
                let lastLineLength;

                let tempLines = [];
                let tempGroups = [];
                lines.forEach((line, lineIndex) => {
                    const [startX, startY, endX, endY] = line.points;
                    if (isDrawing && topBarMode !== 'Draw' && lineIndex === lines.length - 1) {
                        return;
                    }
                    if ((startX === point.x && startY === point.y) || (endX === point.x && endY === point.y)) {

                        const [startPointX, startPointY, endPointX, endPointY] = line.points;

                        if (lineIndex === lines.length - 1 && isDrawing && topBarMode === 'Draw') {
                            lastLine = [startPointX, startPointY, endPointX, endPointY];
                            lastLineLength = lineLength(startPointX, startPointY, endPointX, endPointY)
                            color = color || (line.color ?? 'black');
                            thickness = line.thickness;
                        }
                        else if (line.id === selectedWall?.id) {
                            selectedLine = [startPointX, startPointY, endPointX, endPointY];
                            color = color || (line.color ?? 'black');
                            thickness = line.thickness;
                        }
                        else {
                            tempLines.push(line)
                            tempPoints.push(startPointX, startPointY, endPointX, endPointY);
                        }
                    }
                });
                tempLines.sort((a, b) => {
                    if (a.thickness !== b.thickness) {
                        return a.thickness - b.thickness;
                    }
                    return a.color.localeCompare(b.color);
                });
                let groupSize = 0;
                tempLines.forEach((line, lineIndex) => {
                    const [startX, startY, endX, endY] = line.points;
                    const [startPointX, startPointY, endPointX, endPointY] = (endX === point.x && endY === point.y) ? [endX, endY, startX, startY] : line.points;
                    if (lineIndex === 0 || line.thickness !== tempLines[lineIndex - 1].thickness || line.color !== tempLines[lineIndex - 1].color) {
                        tempGroups.push([]);
                        groupSize++;
                    }
                    tempGroups[groupSize - 1].push(startPointX, startPointY, endPointX, endPointY);
                    tempGroups[groupSize - 1].thickness = line.thickness;
                    tempGroups[groupSize - 1].color = line.color
                })

                let startX, startY, endX1, endX2, endY1, endY2, radius, startAngle, endAngle, angle1, angle2, midAngle, midX, midY, displayAngle;
                if (tempPoints.length >= 4 && lastLine && tempPoints[tempPoints.length - 2] === lastLine[0] && tempPoints[tempPoints.length - 1] === lastLine[1]) {
                    const lastLineStartX = tempPoints[tempPoints.length - 4];
                    const lastLineStartY = tempPoints[tempPoints.length - 3];
                    const lastLineEndX = tempPoints[tempPoints.length - 2];
                    const lastLineEndY = tempPoints[tempPoints.length - 1];
                    const angle = Math.atan2(lastLineEndY - lastLineStartY, lastLineEndX - lastLineStartX);
                    let newX = lastLineEndX + lastLineLength * Math.cos(angle);
                    let newY = lastLineEndY + lastLineLength * Math.sin(angle);
                    let angle1 = Math.atan2(endY1 - startY, endX1 - startX) * (180 / Math.PI);
                    let angle2 = Math.atan2(endY2 - startY, endX2 - startX) * (180 / Math.PI);
                    if (Math.abs(angle2 - angle1) <= 180) {
                        startAngle = Math.min(angle1, angle2);
                        endAngle = Math.max(angle1, angle2);
                    }
                    else {
                        startAngle = Math.max(angle1, angle2);
                        endAngle = Math.min(angle1, angle2) + 360;
                    }
                    newLine = [lastLine[0], lastLine[1], newX, newY];

                    startX = newLine[0];
                    startY = newLine[1];
                    endX1 = newLine[newLine.length - 2];
                    endY1 = newLine[newLine.length - 1];
                    endX2 = lastLine[lastLine.length - 2];
                    endY2 = lastLine[lastLine.length - 1];
                    radius = Math.sqrt(Math.pow(startX - endX1, 2) + Math.pow(startY - endY1, 2));
                    angle1 = Math.atan2(endY1 - startY, endX1 - startX) * (180 / Math.PI);
                    angle2 = Math.atan2(endY2 - startY, endX2 - startX) * (180 / Math.PI);
                    if (Math.abs(angle2 - angle1) <= 180) {
                        startAngle = Math.min(angle1, angle2);
                        endAngle = Math.max(angle1, angle2);
                    }
                    else {
                        startAngle = Math.max(angle1, angle2);
                        endAngle = Math.min(angle1, angle2) + 360;
                    }
                    displayAngle = endAngle - startAngle;
                    if (displayAngle > 90) {
                        newX = lastLineEndX - lastLineLength * Math.cos(angle);
                        newY = lastLineEndY - lastLineLength * Math.sin(angle);
                        angle1 = Math.atan2(endY1 - startY, endX1 - startX) * (180 / Math.PI);
                        angle2 = Math.atan2(endY2 - startY, endX2 - startX) * (180 / Math.PI);
                        if (Math.abs(angle2 - angle1) <= 180) {
                            startAngle = Math.min(angle1, angle2);
                            endAngle = Math.max(angle1, angle2);
                        }
                        else {
                            startAngle = Math.max(angle1, angle2);
                            endAngle = Math.min(angle1, angle2) + 360;
                        }
                        newLine = [lastLine[0], lastLine[1], newX, newY];
                    }
                }


                if (newLine) {
                    startX = newLine[0];
                    startY = newLine[1];
                    endX1 = newLine[newLine.length - 2];
                    endY1 = newLine[newLine.length - 1];
                    endX2 = lastLine[lastLine.length - 2];
                    endY2 = lastLine[lastLine.length - 1];
                    radius = Math.sqrt(Math.pow(startX - endX1, 2) + Math.pow(startY - endY1, 2));
                    angle1 = Math.atan2(endY1 - startY, endX1 - startX) * (180 / Math.PI);
                    angle2 = Math.atan2(endY2 - startY, endX2 - startX) * (180 / Math.PI);
                    if (Math.abs(angle2 - angle1) <= 180) {
                        startAngle = Math.min(angle1, angle2);
                        endAngle = Math.max(angle1, angle2);
                    }
                    else {
                        startAngle = Math.max(angle1, angle2);
                        endAngle = Math.min(angle1, angle2) + 360;
                    }
                    displayAngle = endAngle - startAngle;
                    midAngle = (startAngle + endAngle) / 2;
                    midX = startX + radius * Math.cos(midAngle * (Math.PI / 180));
                    midY = startY + radius * Math.sin(midAngle * (Math.PI / 180));
                }
                return (
                    <React.Fragment key={`group-${index}`}>
                        {tempGroups.length > 0 && (
                            tempGroups.map((group, index) => (
                                <Line
                                    key={index}
                                    points={group}
                                    stroke={group.color}
                                    strokeWidth={group.thickness}
                                    lineJoin="round"
                                    lineCap="miter"
                                    miterLimit={2}
                                />

                            )))}
                        {selectedLine && (
                            <Line
                                points={selectedLine}
                                stroke={"#4C81E2"}
                                strokeWidth={thickness}
                                lineJoin="miter"
                                lineCap="miter"
                                miterLimit={2}
                                opacity={0.8}
                            />
                        )}
                        {newLine && (
                            <Line
                                points={newLine}
                                stroke={"gray"}
                                strokeWidth={1}
                                lineJoin="miter"
                                lineCap="miter"
                                miterLimit={2}
                                dash={[10, 10]}
                            />
                        )}
                        {lastLine && (
                            <Line
                                points={lastLine}
                                stroke={"#3F7AF0"}
                                strokeWidth={thickness}
                                lineJoin="miter"
                                lineCap="miter"
                                miterLimit={2}
                                opacity={0.3}
                            />
                        )}
                        {lastLine && (
                            <Circle
                                x={cursorPosition?.x}
                                y={cursorPosition?.y}
                                radius={10 * (1.5 / scale)}
                                fill="#9BEECD"
                                stroke="#6BD5AB"
                                strokeWidth={10 * (1.5 / scale) * 2 / 10}
                            />
                        )}
                        {newLine && (
                            <Arc
                                x={startX}
                                y={startY}
                                innerRadius={radius}
                                outerRadius={radius}
                                angle={endAngle - startAngle}
                                rotation={startAngle}
                                stroke="grey"
                                strokeWidth={1}
                            />
                        )}

                        {newLine && (
                            <Rect
                                x={midX - 14}
                                y={midY - 22}
                                width={60}
                                height={30}
                                fill="white"
                                stroke="gray"
                                strokeWidth={1}
                            />
                        )}
                        {newLine && (
                            <Text
                                x={midX}
                                y={midY}
                                text={`${displayAngle.toFixed(1)}°`}
                                fontSize={14}
                                fill="black"
                                scaleY={-1}
                            />
                        )}


                    </React.Fragment>
                );
            })}
            {selectedFloor && floorsMapped.map((item, index) => {
                if (item.id === selectedFloor && selectedWall === undefined) {
                    return (
                        <Line
                            key={index}
                            points={shrinkPoints(item.pointList, 4)}
                            stroke={"turquoise"}
                            strokeWidth={2} // Adjust the stroke width as needed
                            closed
                        />
                    )
                }
                else {
                    return null;
                }
            })}
            {floorsMapped.filter(Boolean).map((item, index) => (
                <AreaText 
                    key={index} 
                    points={item.pointList} 
                    index={index} 
                    floor={item} 
                    scale={scale}
                    setSelectedFloor={setSelectedFloor}
                />
            ))}

            {lines.map((line, lineIndex) => {

                const angle = Math.atan2(line.points[3] - line.points[1], line.points[2] - line.points[0]);
                const point1X = line.points[0] - 17 * Math.sin(angle);
                const point1Y = line.points[1] + 17 * Math.cos(angle);
                const point2X = line.points[2] - 17 * Math.sin(angle);
                const point2Y = line.points[3] + 17 * Math.cos(angle);
                if (showMeasurements || (lineIndex === lines.length - 1 && isDrawing))
                    return (
                        <Group key={`wall-${lineIndex}`}>
                            {line.length !== undefined && !(isDrawing && topBarMode !== 'Draw' && lineIndex === lines.length - 1) &&
                                (
                                    <Group>
                                        <Line
                                            points={[point1X, point1Y, point2X, point2Y]}
                                            stroke={"#7A7A7A"}
                                            strokeWidth={1}
                                            lineJoin="miter"
                                            lineCap="miter"
                                            miterLimit={2}
                                        />
                                        <Line
                                            points={[point1X + 10 * Math.sin(angle), point1Y - 10 * Math.cos(angle), point1X - 10 * Math.sin(angle), point1Y + 10 * Math.cos(angle)]}
                                            stroke={"#7A7A7A"}
                                            strokeWidth={1}
                                            lineJoin="miter"
                                            lineCap="miter"
                                            miterLimit={2}
                                        />
                                        <Line
                                            points={[point2X + 10 * Math.sin(angle), point2Y - 10 * Math.cos(angle), point2X - 10 * Math.sin(angle), point2Y + 10 * Math.cos(angle)]}
                                            stroke={"#7A7A7A"}
                                            strokeWidth={1}
                                            lineJoin="miter"
                                            lineCap="miter"
                                            miterLimit={2}
                                        />
                                        <Rect
                                            x={(line.points[0] + line.points[2]) / 2 - Math.min(Math.min(25, 10 / scale) * 4, 40) * Math.sin(angle) - 5}
                                            y={(line.points[1] + line.points[3]) / 2 + Math.min(Math.min(25, 10 / scale) * 4, 40) * Math.cos(angle) + 5}
                                            width={Math.min(95, 38 / scale + 6)}
                                            height={Math.min(30, 17 / scale + 5)}
                                            fill="white"
                                            opacity={0.4}
                                            cornerRadius={5}
                                            offsetX={0}
                                            offsetY={0}

                                            scaleY={-1}
                                        />
                                        <Text
                                            text={measurementSystem !== 'ft' ? `${line.length.toFixed(0) / 100} m` : `${convertMetersToFeet(line.length.toFixed(0))} ft`}
                                            x={(line.points[0] + line.points[2]) / 2 - Math.min(Math.min(25, 10 / scale) * 4, 40) * Math.sin(angle) - scale / 4}
                                            y={(line.points[1] + line.points[3]) / 2 + Math.min(Math.min(25, 10 / scale) * 4, 40) * Math.cos(angle) + scale / 4}
                                            fontSize={Math.min(25, 10 / scale)}
                                            fill="black"
                                            align="center"
                                            offsetX={0}
                                            offsetY={0}
                                            scaleY={-1}
                                            fillAfterStrokeEnabled={true}
                                        />
                                    </Group>
                                )}
                        </Group>)
                else {
                    return null;
                }
            })}

            {/*showMeasurements && points.map((point, index) => {
                let tempLines = [];

                if (lines.length == 0)
                    return null;
                lines.forEach((line, lineIndex) => {
                    const [startX, startY, endX, endY] = line.points;

                    if ((startX === point.x && startY === point.y) || (endX === point.x && endY === point.y)) {
                        const newLine = {
                            points: (endX === point.x && endY === point.y) ? [endX, endY, startX, startY] : line.points,
                            color: line.color ?? 'black',
                            thickness: line.thickness,
                            length: line.length,
                        };
                        tempLines.push(newLine);
                    }
                });
                if (tempLines.length == 0)
                    return null;
                const referenceLine = tempLines[0];
                const [refStartX, refStartY] = referenceLine.points.slice(0, 2);
                const [refEndX, refEndY] = referenceLine.points.slice(-2);
                const calculateAngle = (endX, endY) => {
                    const dx = endX - refEndX;
                    const dy = endY - refEndY;
                    return Math.atan2(dy, dx);
                };
                const centerX = point.x;
                const centerY = point.y;

                tempLines.sort((lineA, lineB) => {
                    const [startXA, startYA, endXA, endYA] = lineA.points;
                    const [startXB, startYB, endXB, endYB] = lineB.points;

                    const angleAEnd = calculatePolarAngle(endXA, endYA, centerX, centerY);
                    const angleBEnd = calculatePolarAngle(endXB, endYB, centerX, centerY);


                    return angleAEnd - angleBEnd;
                });
                if (tempLines.length >= 2)
                    tempLines.push(tempLines[0]);
                if (tempLines.length < 2)
                    return null;
                return tempLines.map((line, lineIndex) => {
                    if (lineIndex === 0)
                        return null;
                    const lastLine = tempLines[lineIndex - 1];
                    const [startX, startY] = line.points.slice(0, 2);
                    const [endX, endY] = line.points.slice(-2);
                    const [start2X, start2Y] = lastLine.points.slice(-2);
                    const [end2X, end2Y] = lastLine.points.slice(0, 2);
                    const ratio = 25 / line.length;
                    const ratio2 = lastLine ? 30 / lastLine.length : 0;

                    const centerX = startX;
                    const centerY = startY;
                    const point1X = startX + (endX - startX) * ratio;
                    const point1Y = startY + (endY - startY) * ratio;
                    const point2X = end2X - (end2X - start2X) * ratio2;
                    const point2Y = end2Y - (end2Y - start2Y) * ratio2;
                    const radius = Math.sqrt((point1X - centerX) ** 2 + (point1Y - centerY) ** 2);

                    const startAngle = Math.atan2(point1Y - centerY, point1X - centerX);
                    const endAngle = Math.atan2(point2Y - centerY, point2X - centerX);
                    let angle1 = endAngle - startAngle;
                    if (angle1 < 0) angle1 += 2 * Math.PI;
                    let angle2 = 2 * Math.PI - angle1;
                    if (angle1 < 0) angle2 += 2 * Math.PI;
                    let smallerAngle = angle2;
                    let adjustedStartAngle = endAngle;
                    const midpointAngle = adjustedStartAngle + (smallerAngle / 2);
                    const textX = centerX + (radius + 15) * Math.cos(midpointAngle) - 12;
                    const textY = centerY + (radius + 15) * Math.sin(midpointAngle) + 6;
                    if (Math.round(smallerAngle * (180 / Math.PI)) >= 180)
                        return null;
                    return (
                        <React.Fragment key={`arc-text-${lineIndex}`}>
                            {<Arc
                                x={centerX}
                                y={centerY}
                                innerRadius={radius}
                                outerRadius={radius}
                                angle={smallerAngle * (180 / Math.PI)}
                                rotation={adjustedStartAngle * (180 / Math.PI)}
                                fill="transparent"
                                stroke="gray"
                                strokeWidth={2}
                            />
                            <Text
                                x={textX}
                                y={textY}
                                text={`${Math.round(smallerAngle * (180 / Math.PI))}°`}
                                fontSize={14}
                                fill="black"
                                align="center"
                                scaleY={-1}
                            /> }
                        </React.Fragment>
                    );
                });
            })*/}

            {points.map((point, index) =>  {
                // console.log("used points ",usedPoints)
                if(!usedPoints.includes(point.id))
                    return null;
                return (

                    <Circle
                        key={`point-${index}`}
                        x={point.x}
                        y={point.y}
                        radius={3}
                        fill="white"
                        stroke="#3F7AF0"
                        strokeWidth={0.3}
                    />
                );
            })}
           

            {orderedItems.map((item, index) => (
                <Group key={item.id}>
                    {/*isRotating && selectedIndex === index && (
                        <Group
                            x={item.x - item.width / scale - 20}
                            y={item.y + item.height / scale + 35}
                            scaleY={-1}
                        >
                            <Rect
                                width={32 / scale}
                                height={16 / scale}
                                fill="#202020"
                                stroke="gray"
                                strokeWidth={1}
                                offsetX={27 / scale }
                            />
                            <Text
                                text={`${rotationValue > 0 ? Math.floor(rotationValue) : 360 + Math.floor(rotationValue)}°`}
                                fontSize={12 / scale}
                                fill="#FFFFFF"
                                align="center"
                                verticalAlign="middle"
                                
                                offsetX={25 / scale}
                            />

                        </Group>
                    )*/}


                    <DragItem
                        shapeRef={shapeRefs.current[index]}
                        handleTransform={handleTransform}
                        imgUrl={item.boardIcon}
                        walls={walls}
                        points={points}
                        scale={scale}
                        dragX={dragX}
                        dragY={dragY}
                        x={item.x} y={item.y}
                        height={item.height}
                        width={item.width}
                        rotation={item.rotation ?? 0}
                        onUpdatePosition={() => {
                            const updatedItems = cloneDeep(items);
                            let clonedPoints = cloneDeep(points);
                            if (selectedItemIndexes.length > 0) {
                                selectedItemIndexes.forEach((id) => {
                                    const shapeRef = shapeRefs.current[id]?.current;
                                    if (shapeRef) {
                                        const { x, y } = shapeRef.getAttrs();
                                        updatedItems[id].x = x;
                                        updatedItems[id].y = y;
                                    }
                                });
                            }
                            setItems(updatedItems);
                            snapshotDispatch({ type: 'SNAPSHOT', payload: { items: updatedItems, walls: walls, points: clonedPoints } });
                        }}
                        onUpdateHeightAndWidth={() => {
                            const updatedItems = cloneDeep(items);
                            let clonedPoints = cloneDeep(points);

                            if (selectedItemIndexes.length > 0) {
                                for (let id of selectedItemIndexes) {
                                    const shapeRef = shapeRefs.current[id]?.current;
                                    if (shapeRef) {
                                        const { height, width, rotation, scaleX, scaleY, x, y } = shapeRef.getAttrs();
                                        const newWidth = width * scaleX;
                                        const newHeight = height * scaleY;

                                        // Update each selected item's dimensions and rotation
                                        updatedItems[id].height = newHeight;
                                        updatedItems[id].width = newWidth;
                                        updatedItems[id].rotation = rotation;
                                        updatedItems[id].x = x;
                                        updatedItems[id].y = y;
                                    }
                                }
                            }

                            setItems(updatedItems);
                            //setSelectedItem(updatedItems[selectedIndex]);
                            snapshotDispatch({ type: 'SNAPSHOT', payload: { items: updatedItems, walls: walls, points: clonedPoints } });
                        }}
                        setDragCount={setDragCount}
                        isDraggable={topBarMode === 'Hand' && selectedItem !== null && (selectedIndex === index || selectedItemIndexes.includes(index)) && selectedWall === undefined}
                        index={index}
                        select={handleMultipleItemClick}
                        unselect={handleUnselect}
                        zIndex={item.zIndex ?? 2}
                        itemCategory={item.title ?? "default"}
                        offsetX={offsetX}
                        offsetY={offsetY}
                        doorDirection={item.direction}
                        scaleX={item.scaleX ?? 1}
                        scaleY={item.scaleY ?? 1}
                        items={items}
                        setItems={setItems}
                        setClickedItem={setClickedItem}
                        setDraggedItem={setDraggedItem}
                    />
                    {selectedIndex === index && (
                        <Transformer
                            ref={transformerRef}
                            rotateEnabled={true}
                            anchorStroke='#3F7AF0'
                            anchorSize={10}
                            borderStroke='#3F7AF0'
                            rotateAnchorCursor='pointer'
                            onTransform={(e) => {
                                setIsRotating(true);
                                const rotation = transformerRef.current.rotation();
                                setRotationValue(rotation);
                            }}
                            onTransformEnd={() => {
                                setIsRotating(false);
                                setRotateEnabled(false);
                                setRotateImgUrl(rotateImg);
                            }}
                            shouldOverdrawWholeArea
                        >
                            {/*!rotateEnabled && 
                            <Group style={{cursor:'pointer'}}>
                                <Image
                                height={item.height/(4/scale)}
                                width={item.width/(2/scale)}
                                offsetY={item.height*scale/4}
                                offsetX={item.width*scale/1.35}
                                image={rotateIcon}
                                onMouseEnter={() => {
                                    setRotateImgUrl(rotateImgHover);
                                }}
                                onMouseLeave={() => {
                                    setRotateImgUrl(rotateImg);
                                }}
                                onClick={() => setRotateEnabled(true)}
                                scaleY={1}
                                scaleX={-1}
                                
                            /></Group>*/}
                        </Transformer>
                    )}




                    {selectedIndex === index && (mouseOnItems(clickedPos)?.panelIcon?.includes("Door")) && !(mouseOnItems(clickedPos)?.panelIcon?.includes("Shower")) && (item?.panelIcon?.includes("Door")) && !(item?.panelIcon?.includes("Shower")) && (
                        <>
                            <Rect
                                x={clickedPos.x - 70}  // Adjust as needed for positioning
                                y={clickedPos.y - 2 + 30}  // Adjust as needed for positioning
                                width={languageCode === 'tr'? 151 : 138}            // Adjust width based on your text length
                                height={18}            // Adjust height based on your text size
                                fill="#2E2E2E"
                                cornerRadius={10}
                                opacity={0.7}   // For rounded corners
                            />
                            <Text
                                text={t(`You can press 'H' to switch the direction.`)}
                                x={clickedPos.x - 32}   // Adjust as needed to center text in the box
                                y={clickedPos.y + 6 + 30}    // Adjust as needed to center text in the box
                                fontSize={6.9}
                                fill="white"         // Set text color to white
                                offsetX={32}
                                offsetY={4}
                                scaleY={-1}
                                align='center'
                            />
                        </>
                    )}
                </Group>
            ))}

             {selectedIndex !== -1 && measureLinesMemo.map((line, index) => {
                return (
                    <Group>
                        <Line
                            points={[line.start.x, line.start.y, line.end.x, line.end.y]}
                            stroke={"#7A7A7A"}
                            strokeWidth={1}
                            lineJoin="miter"
                            lineCap="miter"
                            miterLimit={2}
                        />
                        <Rect
                            x={(line.start.x + line.end.x) / 2 - Math.min(95, 38 / scale + 6) / 2}
                            y={(line.start.y + line.end.y) / 2 + Math.min(30, 17 / scale + 5) / 2}
                            width={Math.min(95, 38 / scale + 6)}
                            height={Math.min(30, 17 / scale + 5)}
                            fill="white"
                            cornerRadius={5}
                            offsetX={0}
                            offsetY={0}

                            scaleY={-1}
                        />
                        <Text
                            text={measurementSystem !== 'ft' ? `${line.distance / 100} m` : `${convertMetersToFeet(line.distance)} ft`}
                            x={(line.start.x + line.end.x) / 2 - Math.min(95, 38 / scale + 6) / 2 + Math.min(95, 38 / scale + 6) / 4}
                            y={(line.start.y + line.end.y) / 2 + Math.min(30, 17 / scale + 5) / 2 - Math.min(30, 17 / scale + 5) / 3}
                            fontSize={Math.min(25, 10 / scale)}
                            fill="black"
                            align="center"
                            offsetX={0}
                            offsetY={0}
                            scaleY={-1}
                            fillAfterStrokeEnabled={true}
                        />
                    </Group>
                );
            })}

        </Layer>
    );
}
export default RoomLayer;